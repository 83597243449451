




/*------------------------------------*\
    $RESET
\*------------------------------------*/

html,body,body,div,span,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,abbr,address,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strong,sub,sup,var,b,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,figure,footer,header,hgroup,menu,nav,section,time,mark,audio,video
{
  margin:0;
  padding:0;
  border:0;
  outline:0;
  font-size:100%;
  vertical-align:baseline;
  background:transparent
  }

  article,aside,figure,footer,header,hgroup,nav,section
  {
    display:block
  }

  ul{
    list-style: none;
  }

    *{
        &,
        &::before,
        &::after{
            @include vendor(box-sizing, border-box);
        }
    }
