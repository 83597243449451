/*------------------------------------*\
    $PAGINACION
\*------------------------------------*/

.navigation{
	&.navigation-mb{
		margin: 1rem 0 2rem 0;
	}
}

.pagination{
	display: inline-block;
	width: 100%;
	margin-bottom: 1em;
    text-align: center;
    @media only screen and (min-width: 769px) {
      text-align: left;
    }
	.action{
		position: relative;
		display: inline-block;
		width: 18px;
		height: 18px;
		vertical-align: middle;
		border: 1px solid $basecolor;

		&:hover{
			background: $thirdcolor;
		}

		&.prev{
			@include rotate(180deg);
		}

		.icon{
			position: absolute;
			display: inline-block;
			top: 50%;
			left: 50%;
			margin-top: -6px;
			margin-left: -3px;
			background: url("#{$img-dir}/template/sprites.png");		
			background-position: -29px -24px;
			width: 7px;
			height: 10px;
		}
	}
}

	.pagination-number{
		display: inline-block;
		margin: 0 1em;

		li{
			@extend .list-bullets;
		}
	}

	.list-bullets{
		display: inline-block;
		vertical-align: middle;

		&:last-child{margin: 0;}

		&.active{
			a{
				background: $thirdcolor;
				@include border-radius(50%, 50%, 50%, 50%);
			}
		}

		a{
			display: block;
		    padding: .25em .5em;
		    line-height: 1;
		    color: $secondarycolor;
		    text-align: center;

		    &:hover{
		    	background: $thirdcolor;
				@include border-radius(50%, 50%, 50%, 50%);
		    }
		}
	}