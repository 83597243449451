




/*------------------------------------*\
    $BREAKPOINTS
\*------------------------------------*/
// Break point x2 --> Retina Display  //////////////////////
@media
	only screen and (-webkit-min-device-pixel-ratio: 1.5),
	only screen and (min--moz-device-pixel-ratio: 1.5),
	only screen and (min-device-pixel-ratio: 1.5) {
@import "2x"; }

// Mobile First
////////////////////////////////////////

// Break point 1  //////////////////////
//@media only screen and (min-width: $bp1) {
//	@import "breakpoints/bp1.scss";
//}
//
//// Break point 2  //////////////////////
//@media only screen and (min-width: $bp2) {
//	@import "breakpoints/bp2.scss";
//}
//
//// Break point 3  //////////////////////
//@media only screen and (min-width: $bp3) {
//	@import "breakpoints/bp3.scss";
//}
//
//// Break point 4  //////////////////////
//@media only screen and (min-width: $bp4) {
//	@import "breakpoints/bp4.scss";
//}
//
//// Break point 5  //////////////////////
//@media only screen and (min-width: $bp5) {
//	@import "breakpoints/bp5.scss";
//}
//
//// Break point 6  //////////////////////
//@media only screen and (min-width: $bp6) {
//	@import "breakpoints/bp6.scss";
//}


// Desktop First
////////////////////////////////////////

// Break point 6  //////////////////////
@media only screen and (max-width: $bp6) {
	@import "breakpoints/bp6.scss";
}

// Break point 5  //////////////////////
@media only screen and (max-width: $bp5) {
	@import "breakpoints/bp5.scss";
}

// Break point 4  //////////////////////
@media only screen and (max-width: $bp4) {
	@import "breakpoints/bp4.scss";
}

// Break point 3  //////////////////////
@media only screen and (max-width: $bp3) {
	@import "breakpoints/bp3.scss";
}

// Break point 2  //////////////////////
@media only screen and (max-width: $bp2) {
	@import "breakpoints/bp2.scss";
}

// Break point 1  //////////////////////
@media only screen and (max-width: $bp1) {
	@import "breakpoints/bp1.scss";
}






