/*------------------------------------*\
    $SITIO
\*------------------------------------*/


@-webkit-viewport {
	width : device-width;
}

@-moz-viewport {
	width : device-width;
}

@-ms-viewport {
	width : device-width;
}

@-o-viewport {
	width : device-width;
}

@viewport {
	width : device-width;
}

html {
	background : $background;
	font-size : 100%;
	-webkit-overflow-scrolling : touch;
	-webkit-tap-highlight-color : lighten($basecolor, 75%);
	-webkit-text-size-adjust : 100%;
	-ms-text-size-adjust : 100%;
}

body {
	position:relative;
	margin : 0;
	padding : 0;
	width : 100%;
	color : $textcolor;
	background-color : $background;
	overflow-x:hidden;
	line-height : $baselineheight;
	font-family : $basefont;
	font-stretch: condensed;
	@include font-size(16);
}

.fll{ float: left; }
.flr{ float: right; }
.fln{ float: none; }
.mr{ margin-right: 1em;}
.mb{ margin-bottom: 1em;}
.text-right-on-768{
  text-align: right;
  margin-right: 1em;
  @media only screen and (min-width: 769px) {
    text-align: left;
  }
}
main{
  padding: 0 1em;
}
/*
*	HEADER
*/
header.header{
	display: table;
	width: 95%;
	max-width: $pagemaxwidth;
	margin: 2em auto 1.5em auto;

	.wrapper{

        .logo-cont{
            @include col(7/12);
            a{
              display: block;
              img{
                max-width: 100%;
              }
            }

			.logo{
				margin-bottom: 0.2em !important;
			}
        }

        .social-cont{
            text-align: right;
            @include col(5/12);
        }
	}

	@media only screen and (max-width: 769px) {
		margin: 0em auto 1.5em auto;
	}

}

	/*
	*	SOCIAL
	*/
	.social{
		margin-bottom: 1.5em;

		>ul{
			display: inline-block;
			li{
				display: inline-block;
			}
		}

		a{
			position: relative;
			margin: 0 .5em 0 0;
			padding: 0 .5em 0 0;
			border-right: 2px solid $basecolor;
		}

		.social-networks{
			display: inline-block;
			vertical-align: middle;
			a{
				margin: 0 .15em;
				padding: 0 .15em;
				border: 0;
			}
		}
	}

	/*
	*	OPTIONS WEBSITE
	*/
	.options--website{


		.language{
			position: relative;
			display: inline-block;
			width: 100%;
			max-width: 34px;
			height: 20px;
			padding-right: 1em;
			overflow: hidden;
			vertical-align: middle;
			@include transition(.5s all ease-in-out);

			.arrowLanguage{
				display: block;
				position: absolute;
				top: 50%;
				margin-top: -2px;
				right: 5px;
				background: url("#{$img-dir}/template/sprites.png");
				background-position: -9px -9px;
				width: 5px;
				height: 8px;
				@include rotate(-180deg);
			}

			&.active{
				max-width: 90px;
				@include transition(.5s all ease-in-out);

				.arrowLanguage{
					@include rotate(0);					
				}

				&.multi{
					max-width: 230px;
				}
			}
			
			ul{
				display: inline-block;
				position: absolute;
				top: 0;
				right: 1em;
				width: 75px;
				vertical-align: middle;

				&.multi{
					width: 100%;
				}
			
				li{display: inline-block;}
			}
		
		}

		a{
			display: inline-block;
			vertical-align: middle;
			margin-left: .25em;
			padding-left: 2px;

			&.active{
				font-weight: bold;
			}

			&.link-search{
				margin-left: .5em;
			}
		}

        .link-menu-toggle{
            display: none;
            float: left;
			width: 30px;
        }
	}

/*
*	FOOTER
*/
footer{
	display: table;
	width: 100%;

	>.wrapper{
		display: table;
		width: 95%;
		max-width: $pagemaxwidth;
		margin: 4.6875em auto;

		.col{
			display: table-cell;
			vertical-align: middle;

			& + .col{
				text-align: right;
			}
		}
	}
}

	/*
	*	INFO WBSITE
	*/
	.info-website{
		width: 100%;
		background: $grey;

		>.wrapper{
			@include flexbox();
			@include flex-wrap(wrap);
			width: 100%;
			max-width: $pagemaxwidth;
			margin: 0 auto;

			.col{
				display: inline-block;
				width: 47.85%;
				margin: 1em 1.074%;
				padding: 1em;
				vertical-align: middle;
				background: $white;
			}
		}

		h4{
			margin-bottom: 1em;
			text-transform: uppercase;
			color: $secondarycolor;
			font-weight: bold;
		}
	}

		.last-news{
			@include font-size(14);

			li{
				padding: .875em 0;
				border-bottom: 1px solid $secondarycolor;

				&:first-child{
					padding-top: 0;
				}

				&:last-child{
					border: 0;	
				}
			}

			.title{
				position: relative;
				display: block;
				width: 100%;
				margin-bottom: .5em;
				padding-right: 1rem;
				color: $secondarycolor;

				&:after{
					content: "";
					display: block;
					position: absolute;
					top: 2px;
					right: 10px;
					background: url("#{$img-dir}/template/sprites.png");
					background-position: -28px -8px;
					width: 10px;
					height: 11px;
				}
			}

			h5{
				color: $secondarycolor;
				font-family: $basefont;
				font-size: 1rem;
			}

			p{margin: 0;}
		}

	/*
	*	OTHER INFO
	*/
	.other-info{
		display: inline-block;
		width: 100%;
		margin: 1.5em 0;

		li{
			display: inline-block;
			vertical-align: middle;
			padding: 0 .5em;

			&:last-child{
				padding-right: 0;
			}
		}

		a{
			color: $secondarycolor;
			font-weight: bold;
		}
	}

	/*
	*	LEGAL INFO
	*/
	.legal-info{
		@include font-size(14);
		
		p{margin: 0;}
	}

/*
*	SEARCH
*/
.search{
	display: block;
	width: 100%;
	max-height: 0;
	overflow: hidden;
	background: $greyLight;
	@include transition(1s all ease-in-out);

	&.active{
		max-height: 100vh;
		@include transition(1s all ease-in-out);
	}

	.wrapper{
		position: relative;
		display: table;
		width: 100%;
		max-width: $pagemaxwidth;
		margin: 0 auto;
		
		&:after{
			content: "";
			position: absolute;
			top: -6px;
			right: 5px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 6px 7px 6px;
			border-color: transparent transparent $grey transparent;
		}
	}

	input, select{border: 0;}

	.close{
		display: inline-block;
		vertical-align: middle;
		margin-left: 1em;
		@include font-size(22);
	}

    &.search-mobile{
      display: none;
      position: relative;
      overflow: visible;
      .wrapper {
        overflow: visible;

        &:after {
          right: 23px;
        }
      }
      .form-search{
        padding-top: 30px;
        padding-bottom: 8px;
        padding-left: 12px;
        padding-right: 12px;
        display: block;
        .searchText{
          width: 100%;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          padding: 20px 10px;
          margin-bottom: 8px;
          background-color: #e6e2e1;
        }
        .col{
          width: 50%;
          display: inline-block;
        }
        input[type="submit"]{
          float: right;
        }
      }
      .close-search{
        position: absolute;
        right: 12px;
        top: 12px;
        font-size: 10px;
        margin: 0;
        padding: 0;
      }
      &.active{
        display: block !important;
      }


      @media only screen and (min-width: 769px) {
        display: none !important;
        &.active{
          display: none !important;
        }
      }
    }
    &.search-desktop{
      display: none !important;
      @media only screen and (min-width: 769px) {
        &.active{
          display: block !important;
        }
      }
    }
}

	/*
	*	FILTERS
	*/
	.filter{
		display: inline-block;
		width: 100%;

		.title--filter{
			margin-bottom: .5em;
			color: $secondarycolor;
			text-transform: uppercase;
			@include font-size(18);
		}


		&.filter--common{
			padding: .5em 1em;
			background: $thirdcolor;

			form{width:100%;}
		}
		
		&.filter-works{
			.select--custom{
				width: 195px;
	    		min-width: 195px;
			}

			input{max-width: 275px;}
		}

		&.filter-publish{
			.item--form{
				&:first-child{width: 32.5%;}
				&:nth-child(2){width: 50% !important;}
				input{max-width: 380px;}
			}
		}

		&.filter-mb{
			margin-bottom: 2rem;
		}
	}

		.filter-multimedia{
			.category{
				width: 30%;
				.select--custom{width: 90%;}
			}
			.keywords{width: 56.5% !important;}
			.button{padding-right: 1rem;}
		}

/*
*	CONAINER
*/
	/*
	*	STYLES COMMON
	*/
	.section{
		width: 100%;
		
		.row{
			width: 100%;

			.wrapper{
				max-width: $pagemaxwidth;
				margin: 0 auto;
				width: 100%;
			}
		}
	}

	/*
	* ALERT INDEX
	*/
	#special-timetable{
		margin: 0em !important;
		.btn--special {
			font-size: 14px !important;
			text-align: center !important;
			padding: 0rem !important;
			background: $whiteTransparent !important;
			color: #1980cb !important;
			margin: 0em !important;
		}

		@media only screen and (max-width: 769px) {
			margin-top: 0px;
		}
	}


	/*
	*	DASHBOARD
	*/
	.dashboard{
		position: absolute;
	    right: 0;
	    left: 0;
        bottom: 40px;
	    background: $whiteTransparent;
        width: 100%;

        text-align: right;
        @media only screen and (min-width: 769px) {
          top: 0;
          right: 0;
          width: 315px;
          left: auto;
          bottom: auto;
          width: 280px;
          height: 345px;
          min-height: 345px;
        }

	    li{
    		display: table;
    		width: 100%;
	    	height: 16.333333%;
	    	border-bottom: 1px solid $white;
	    	@include box-sizing();


            width: 100%;
            margin: auto;
            text-align: left;

            @media only screen and (min-width: 769px) {
              height: 33.3333333%;
              width: 100%;
              text-align: center;
            }

	    	&:last-child{border: 0;}

	    	a{
	    		position: relative;
	    		display: table-cell;
	    		padding: 0.725em 0.725em 0.725em 2.650em;
	    		vertical-align: middle;
	    		color: $secondarycolor;
	    		@include font-size(22);

                @media only screen and (min-width: 769px) {
                  text-align: left;
				}
				@media only screen and (max-width: 769px) {
					padding: 0.300em 0.300em 0.300em 1.300em;
				}


	    		&:after{
	    			content: "";
					display: block;
					position: absolute;
					top: 29px;
					margin-top: -5px;
					right: 25px;
					background: url("#{$img-dir}/template/sprites.png");
					background-position: -28px -8px;
					width: 10px;
					height: 11px;
	    		}

	    		&:hover{
	    			background: $whiteTransparentLight;
	    		}

			    &.db-icon:º{
				    content: "";
				    display: block;
				    position: absolute;
				    top: 29px;
				    margin-top: -20px;
				    background: url("#{$img-dir}/template/sprites.png");
				    left: 15px;
				    width: 30px;
				    height: 30px;
			    }

				@media only screen and (min-width: 769px) {
					&.db-icon:before, &:after{
						top: 55px;
					}
				}

				@media only screen and (max-width: 769px) {
					&.db-icon:before, &:after{
						top: 20px;
					}
				}

				&.db-icon-1:before{
				    background-position: -7px -102px;
			    }

			    &.db-icon-2:before{
				    background-position: -58px -104px;
			    }

			    &.db-icon-3:before{
				    background-position: -110px -104px;
			    }

	    	}

	    	h3{margin:0;}
	    }
	}

	.hidden-mobile{
		@media only screen and (min-width: 769px) {
			display: block;
		}
		@media only screen and (max-width: 769px) {
			display: none;
		}
	}

	.hidden-mobile-inline{
		@media only screen and (min-width: 769px) {
			display: inline-block;
		}
		@media only screen and (max-width: 769px) {
			display: none;
		}
	}

	.visible-mobile{
		.dashboard{
			position: relative;
			bottom:0px;
			background: $lightergrey;

			li {
				color: $basecolorLight;
			}
		}
		@media only screen and (max-width: 769px) {
			display: table !important;
		}
		@media only screen and (min-width: 769px) {
			display: none !important;
		}
	}


	/*
	*	CALENDAR
	*/
	.calendar{
		padding: .5em 1em 1em 1em;
		background: $thirdcolor;

		.date-actual{
			margin-bottom: .5em;
			color: $secondarycolor;
	    	@include font-size(20);
		}

		.calendar--wrapper{
		}
	}

		.news-day{

			.news-day-title,
			h4{
				margin: 0;
				padding: .5em;
				text-transform: uppercase;
				color: $white;
				background: $basecolorLight;
				@include font-size(14);
			}

			.news-day__featured{
				background: $white;

				li{
					display: inline-block;
					width: 100%;
					padding: .75em .5em;
					border-bottom: 1px solid $basecolorDark;
					color: $secondarycolor;
					@include font-size(14);

					&:last-child{border: 0;}

					span{
						display: block;
						text-overflow: ellipsis;
						white-space: nowrap;
						overflow: hidden;
					}
				}
			}

			p{margin: 0;}
		}

	/*
	*	BREADCRUMBS
	*/
	.breadcrumbs{
		display: table;
		width: 100%;
		max-width: $pagemaxwidth;
		margin: 1em auto;

		.list-rute{
			li{
				display: inline-block;
				font-weight: bold;

				&:last-child{
					font-weight: normal;

					a{
						&:after{
							content: none;
						}
					}
				}
			}
		}

		a{
			color: $secondarycolor;
			@include font-size(14);

			&:after{
				content: "";
				display: inline-block;
				margin: 0 .5em;
				vertical-align: middle;
				background: url("#{$img-dir}/template/sprites.png");
				background-position: -29px -25px;
				width: 7px;
				height: 10px;
			}
		} 
	}

	/*
	*	ROW
	*/
	.row{
		display: table;
		width: 100%;
	}
		
		.row--list{
			.col-2{
				display: table-cell;
				vertical-align: top;
				width: 50%;
			}
		}

	/*
	*	LIST ELEM
	*/
	.list{
		width: 100%;
		margin: .5em 0;

		&.list--only{margin: 0;}
		&.list--space{padding: 1.5em 0;}
		&.list--bottom{margin: 0 0 2rem 0;}
		&.list--bottom--b{margin: 0 0 4rem 0;}

		&.list--spacing--content{
			.content{padding: 1rem .35rem 0 .35rem;}
		}

		&.list--spacing--content--ma{
			.content{padding: 1rem 0 0 0;}
		}

		.list--title{
			display: inline-block;
			margin-bottom: .5em;
			text-transform: uppercase;
			color: $secondarycolor;
			@include font-size(24);
		}

		p:empty{display: none;}

	}
	
	.list-elem{
		@include flexbox();
		@include flex-wrap(wrap);
		width: 100%;

		>li{
			display: inline-block;
			vertical-align: middle;
			margin: 0 0 1em 0;
			
			&:last-child{margin-right: 0 !important;}

		}

		&.list-elem__2{
			>li{
				width: 49.21875%;
				margin-right: 0.78125%;

				&:nth-child(even){margin-right: 0 !important;}
			}
		}

		&.list-elem__3{
			>li{
				width: 32.8125%;
				margin-right: 0.78125%;
                .list--title{
                  height: auto;
                  &.calendar{
                    height: auto;
                    display: none;
                  }
                }
                @media only screen and (min-width: 385px) {
                  width: 49.5%;
                  margin-right: .5%;
                  &:nth-child(3n){margin-right: 0% !important;}
                  &:nth-child(2n){margin-right: 0 !important;}
                  .list--title{
                  }
                }
                &:nth-child(3n){margin-right: 0 !important;}
                @media only screen and (min-width: 640px) {
                  .list--title{
                    &.calendar{
                      height: 31px;
                      background: white;
                      display: block;
                    }
                  }
                }
                @media only screen and (min-width: 769px) {
                  width: 32.8125%;
                  margin-right: 0.78125%;
                  &:nth-child(2n){margin-right: 0.78125% !important;}
                  &:nth-child(3n){margin-right: 0 !important;}
                  .list--title{
                    height: 31px;
                    &.calendar{
                      height: auto;
                      display: none;
                    }
                  }
                }

			}
		}

		&.list-elem__4{
			>li{
				width: 24.51171875%;
				margin-right: 0.65104165%;

				&:nth-child(4n){margin-right: 0 !important;}

                @media only screen and (min-width: 385px) {
                  width: 49.5%;
                  margin-right: .5%;
                  &:nth-child(2n){margin-right: 0 !important;}
                }
                @media only screen and (min-width: 769px) {
                  width: 24.5%;
                  margin-right: 0.66666%;
                  &:nth-child(2n){margin-right: 0.666666% !important;}
                  &:nth-child(4n){margin-right: 0 !important;}
                }
			}
		}
	}

		/*
		*	LIST ELEM COLECTION
		*/
		.list-elem__3{
			.image{
				height: 305px;
				min-height: 305px;
			}

			.notice{
				height: 190px;
				min-height: 190px;	
			}
		}

		.list-elem__2, .list-elem__4{
			.image{
				height: auto;
				min-height: 230px;
			}
		}

		.list-elem-small{
			.image{
				height: 175px;
				min-height: 175px;
			}	
		}

		.list-elem-medium{
			.image{
				height: 310px;
				min-height: 310px;
			}	
		}

		.list-elem-big{
			.image{
				height: 460px;
				min-height: 460px;
			}	
		}

		.list-elem--colection{

			.image{
				position: relative;
				display: block;
				width: 100%;
			}

			.content{
				/* padding: 1em .25em; */
				padding: .25em 0;
				
				&.content--mt{
					padding: .5rem .25rem;
				}
	
				&.content--small{
					p{
						margin: 0;
						@include font-size(14);
					}
				}
			}

			.category{
				text-transform: uppercase;
				color: $secondarycolor;
			}

			.category, .author, .date{
				display: block;
				@include font-size(14);
			}

			.btn{margin-top: 1.75em;}
		}

	/*
	*	TITLES
	*/
	.title{
		display: block;
		margin-bottom: .25em;
		line-height: 1;
		color: $secondarycolor;
		@include font-size(18);

		&.title--primary{
			position: relative;
			height: 48px;
			min-height: 48px;
			margin-bottom: .5em;
			
			strong{
				position: absolute;
				bottom: 0;
			}
		}

		&.title--base{
			color: $basecolor;
		}

		&.title--mb{
			margin-bottom: .5rem;
		}

		&.title__18{
			@include font-size(18);					
		}

		&.title__14{
			@include font-size(14);					
		}
	}

	.title--second{
		line-height: 1;
		text-transform: uppercase;
		font-weight: bold;
		@include font-size(18);
	}

	.title--classic{
		color: $secondarycolor;
		font-family: $basefont;
		line-height: 1;
        //margin: 10px 0;
		font-size: 1.2rem;

		&.title--classic__18{
			@include font-size(18);					
		}

		&.title--classic--base{
			color: $basecolor;
		}

		&.title--classic--italic{
			font-style: italic;
		}

		&.title--classic--ma{
			margin: 0;
		}

		&.title--classic--mb{
			margin-bottom: 1rem;
		}

		a{
			color: $secondarycolor;
		}
	}

	/*
	*	IMAGE
	*/
	.image{
		.block{
			height: 100%;
		}
	}

/*
*	PAGES
*/
.title--page{
	margin: 0 0 .75em 0;
	color: $secondarycolor;
	text-transform: uppercase;
	@include font-size(30);

	&.m--small, &.m--small-2{margin: 0 0 .25em 0;}

	&.lh--1{ line-height: 1; }

	&.subtitle--page{
		@include font-size(24);
	}

	&.subtitle--page__18{
		@include font-size(18);	
	}

}

	.title--custom{
		position: relative;

		&:after{
			content: "";
			position: absolute;
			left: -38px;
			top: 50%;
			background: url("#{$img-dir}/template/sprites.png");
		}

		&.normas{
			&:after{
				margin-top: -17px;
				background-position: -414px -60px;
				width: 27px;
				height: 35px;
			}
		}

		&.audio{
			&:after{
				left: -44px;
				margin-top: -15px;
				background-position: -555px -60px;
				width: 38px;
				height: 30px;
			}
		}

		&.hanger{
			&:after{
				left: -40px;
				margin-top: -13px;
				background-position: -598px -60px;
				width: 37px;
				height: 27px;
			}
		}
		&.virtual{
			&:after{
				left: -44px;
				margin-top: -16px;
				background-position: -454px -60px;
				width: 36px;
				height: 32px;
			}
		}
		&.mobile{
			&:after{
				margin-top: -18px;
				background-position: -362px -60px;
				width: 23px;
				height: 35px;
			}
		}
		&.picture{
			&:after{
				margin-top: -16px;
				background-position: -507px -60px;
				width: 33px;
				height: 32px;
			}
		}
		&.accessibility{
			&:after{
				margin-top: -16px;
				background-position: -255px -105px;
				width: 33px;
				height: 32px;
			}
		}
	}

	.featured--page{
		margin-bottom: 2em;

		.image-floated{
			float: left;
			padding: 5px 20px 0 0;
		}

		.image{
			display: block;
			margin-bottom: 1em;
            img{
              width: 100%;
            }
		}
	}
	
	/*
	*	SECTION FEATURED
	*/
	.section--featured{
        display: inline-block;
        width: 100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
		margin-bottom: 2rem;

		&.section--featured-mb{
			margin-bottom: 4rem;
		}

		&.section--featured__2{
			>.col{
				&:first-child{
					width: 100%;
                    margin-bottom: 1em;
                  @media only screen and (min-width: 769px) {
                    width: 51%;
                    margin-bottom: 0;
                  }
				}
			}

			.section--featured__child{
				height: auto;
				min-height: auto;
			}
		}

		&.section--featured__3{
			>.col{
                width: 29%;
				&:first-child{
					width: 70%;
				}
			}
		}

		>.col{
			display: inline-block;
			vertical-align: top;
			width: 48%;
            padding-left: 2%;
			@media only screen and (max-width: 768px) {
				padding-left: 0;
			}

			&:first-child{
              padding-right: 2%;
              padding-left: 0;
			}
		}

		>.col-3{
			display: inline-block;
			vertical-align: top;
			width: 31%;
			padding: 0 1%;
		}

		.image{margin-bottom: 1em;}

        .template-work__image{
            float: left;
            margin: 0 1rem 1rem 0;
        }

		&.section--featured-image{
			img{
				//max-width: 490px;
			}
		}
	}



	.section--featured__child{
		//min-height: 1em;

		.image{
			//min-height: 255px;

			img{
				//max-height: 320px;
			}
		}

		p{
			min-height: 1em;
		}

		p:empty{display: none;}
	}

	/*
	*	VISITA
	*/
	.timetable{
		display: block;
		width: 100%;
		margin-bottom: 3.25em;

		.col{
			display: inline-block;
            width: 100%;
			vertical-align: top;

			& + .col{
              padding: 0 20px;
            }
          img{
            max-width:100%;
          }
		}
        @media only screen and (min-width: 769px) {
          .col{
            width: 65%;
            & + .col{
              width: 35%;
            }
          }
        }
		
		ul{
			li{
				margin-bottom: 1em;
				padding-left: 2em;
			}
		}

		.btn{
			margin-top: 1.25em;
		}

		p{margin: 0;}
	}

		.title--classic__visit{
			position: relative;
			margin-bottom: .5em;

			&:after{
				content: "";
				position: absolute;
				left: -38px;
				top: 50%;
				background: url("#{$img-dir}/template/sprites.png");
			}

			&.covid19{
				&:after{
					margin-top: -12px;
					background-position: -314px -106px;
					width: 26px;
					height: 35px;
				}
			}

			&.service{
				&:after{
					margin-top: -18px;
					background-position: -414px -60px;
					width: 27px;
					height: 35px;
				}
			}

			&.horary{
				&:after{
					margin-top: -12px;
					background-position: -59px -63px;
					width: 26px;
					height: 25px;
				}
			}

			&.horary-2{
				&:after{
					margin-top: -17px;
					background-position: -210px -104px;
					width: 32px;
					height: 32px;
				}
			}

			&.rate{
				&:after{
					margin-top: -19px;
					background-position: -8px -56px;
					width: 25px;
					height: 39px;
				}
			}

			&.locale{
				&:after{
					margin-top: -15px;
					background-position: -111px -62px;
					width: 24px;
					height: 29px;
				}
			}

			&.people{
				&:after{
					margin-top: -10px;
					background-position: -158px -19px;
					width: 31px;
					height: 20px;
				}
			}

			&.zoom-vertical{
				&:after{
					margin-top: -13px;
					background-position: -210px -14px;
					width: 26px;
					height: 26px;
				}
			}

			&.ajusts{
				&:after{
					margin-top: -11px;
					background-position: -259px -16px;
					width: 28px;
					height: 23px;
				}
			}

			&.arrow-double-down{
				&:after{
					margin-top: -12px;
					background-position: -316px -16px;
					width: 17px;
					height: 14px;
				}
			}
		}

	/*
	*	VISIT GUIDE
	*/
	.visit{
		display: table;
		width: 100%;
		margin-bottom: 2em;

		.col{
			display: table-cell;
			vertical-align: top;

			&:first-child{
				width: 55%;
				padding-right: 5%;
			}
		}

		.visit--description{
			li{margin-bottom: 1em;}
			p{margin:0;}
		}
	}

		/*
		*	LIST WORKS
		*/
		.list--works{
			margin-bottom: 2em;
			
			.content{
				padding: 1em .25em 0 .25em;
			}

			.work{
				.author{@include font-size(16);}
				.title{
					font-style: italic;
					@include font-size(18);
				}
			}
		}

		/*
		*	LIST RELATIONSHIP
		*/
		.list-relationship{
			.title{
				@include font-size(18);
			}
		}

		.list-visit{
			.title{
				color: $secondarycolor;
				@include font-size(24);
			}
		}

		/*
		*	LIST EDUCATIONAL
		*/
		.list-box{
			li{
				display: inline-block;
				width: 100%;
				margin-bottom: 1.5em;
			}

			.wrapper{
				display: table;
				margin: 0 !important;

				.col{
					display: inline-block;
					vertical-align: top;
                    width: 49%;
					@media only screen and (max-width: 768px) {
						width: 100%;
					}

                  &:first-child{
						//width: 260px;
					}

					& + .col{
						padding-left: 1rem;
					}
				}
			}

			h4{
				color: $secondarycolor;
				font-weight: bold;
			}

			p{
				margin-bottom: .75em;
				@include font-size(14);
			}

		}

	/*
	*	HORARIO
	*/
	.row-other-info{
		background: $thirdcolor;
	}

		.table-info{
			@include flexbox();
			@include flex-wrap(wrap);
			width: 100%;
			margin-bottom: 1.5em;

			.col{
				display: inline-block;
				width: 50%;
				vertical-align: top;
			}

			&.table-info--2{
				.col{
					padding-right: 5%;

					& + .col{
						padding-right: 0;
					}
				}
			}

			&.table-info--3{
				.col{
					width: 33.3333%;
				}
			}
			
			p{margin: 0;}

			ul{
				padding-left: 2.5em;
				
				li{
					margin-bottom: 1em;
				}

				&.list-none{padding: 0;}

				&.sublist{
					padding-left: 1em;
					margin-bottom: .5em;

					&.list-style{list-style: disc;}

					li{margin:0;}
				}
			}

			.btn{margin-top: 1em;}
		}

		.horarys{
			.cite{
				margin: 2em 0 1em 0;
				padding: 1em;
				background: $thirdcolor;
			}

			.address{margin-bottom: 1em;}

		}

		.rates{
			.col{
				& + .col{padding-top: 2.35em;}
			}
		}

		.space{
			margin: 2rem 0;
		}

		/*
		*	MAPS
		*/
		.maps {
    	    position: relative;
	        padding-bottom: 40%; // This is the aspect ratio
	        height: 0;
	        overflow: hidden;
	    }

	    .maps iframe {
	        position: absolute;
	        top: 0;
	        left: 0;
	        width: 100% !important;
	        height: 100% !important;
	    }

	    /*
	    *	BOARD
	    */
	    .board{
	    	width: 100%;
			//margin: 2em 0;
			padding: .5em;

			&.board--color{
				background: $thirdcolor;
			}

			&.board--m-b{
				margin: 3em 0;
			}

			.social-network{
				display: inline-block;
				vertical-align: middle;

				&.right{float: right;}
			}
	    }

	/*
	*	TEMPLATES
	*/
	.template-detail{
		&.vertical{
			.template-work__image{
				display: inline-block;
			    //float: left;
			    margin: 1em 0;
                width: 100%;
                max-width: initial;
                @media only screen and (min-width: 481px) {
                  margin: 0 2% 1em 0;
                  width: 55%;
                }
			}

			.template-work__description--resume{
				margin: 0;
				padding: 0;
                display: inline-block;
                width: 100%;
                @media only screen and (min-width: 481px) {
                  width: 42%;
                }
			}
		}

		.template-detail__footer{
			@extend .board;
		}

	}
	
	.template-col{

		.template_body{
			display: table;
			width: 100%;
		}

		.col{
			position: relative;
			display: table-cell;
			width: 50%;
			vertical-align: top;

			&:first-child{
			/* 	padding: .5em 0; */
				text-align: center;
			}
			
			& + .col{
				padding-left: 2rem;
			}
		}

		.image{
			height: 350px;
			min-height: 350px;
		}
	}

		//WORK
		.template-work{
			
			.template-work__image{
			    display: inline-block;
				vertical-align: top;
				width: 100%;
			    max-width: 58%;
			    margin: 0 1.5% 1em 0;
			    //cursor: pointer;
			}		

			.template-work__description--resume{
				position:relative;
			    display: inline-block;
				vertical-align: top;
				width: 40%;
				margin-bottom: 2em;
				padding-bottom: 3em;

				.author{@include font-size(18);}

				.title{
					color: $secondarycolor;
					font-style: italic;
					@include font-size(24);
				}

				.no-italic{
					font-style: normal;
				}

				.author, .title{margin: 0;}

				li{margin-bottom: 1em;}
				p{margin:0;}
			}

			.lending{
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				padding: .25em .5em;
				background: $thirdcolor;
			}

			.lease{
				padding: 0.5em;
				background: $thirdcolor;

				.btn{margin-top: 1rem;}
			}
		}

		//EXPOSITION
		.template-exposition{
			@extend .template-col;

			.share-options{
				width: 100%;
				//margin-left: 2rem;
				padding: .5em;
				background: $thirdcolor;
			}
			margin-bottom: 30px;
		}

		//PUBLISH
		.template-publish{
			@extend .template-col;

			.col{
				&:first-child{background: $thirdcolor;}
			}

			.characteristics{
				p{margin:0;}
				
				.price{
					margin: 2.5rem 0 2rem 0;
					@include font-size(24);
				}
			}
		}

		//SPACE
		.template-space{
			.image{
				margin: 1rem 0 2rem 0;
			}

			.characteristics{
				ul{
					@extend .sublist;
					@extend .list--disc;

					li{margin: 0 !important;}
				}
			}
		}


		/*
		*	RETURN && SHARE
		*/
		.return--share{
			width: 100%;
		    margin: 2em 0;
		    padding: .5em;
		    background: $thirdcolor;
		}

		/*
		*	ZOOM POPUP
		*/
		.zoom--close, .zoom--options{
			position: absolute;
			top: 16px;
			display: inline-block;
			width: 50px;
			text-align: center;
			@include font-size(24);
		}

		.zoom--close{
			left: 0;

			.icon{
				display: inline-block;
				background: url("#{$img-dir}/template/sprites.png");
				background-position: -114px -20px;
				width: 18px;
				height: 18px;
			}
		}
		.zoom--options{right: 0;}

			.zoom--options{
				a{
					display: block;
    				margin: .25em 0;
				}
			}

		.zoom--viewer{
            position: relative;
            display: inline-block;
            vertical-align: top;
            width: 100%;
            height: 550px;
        }
		.zoom--description{
			display: table;
			width: 100%;
			margin: 1em 0 0 0;

			.col{
				display: table-cell;
				width: 50%;
				vertical-align: top;

				& + .col{text-align: right;}
			}

			p, h3, h5{margin: 0;}
		}

	/*
	*	PUBLISH
	*/
	.list--publish{
		.title{
			color: $secondarycolor;
			@include font-size(18);
		}
	}
	
	/*
	*	OTHER SERVICES
	*/
	.other-services{
		.col{
			margin-bottom: 1em;
			padding-right: 1em;
		}
	}

	/*
	*	TRAVELS
	*/
	.content-travel{
		max-width: 175px;
		padding: 1em .25em !important;

		.btn{margin-top: .5em;}
	}

	/*
	*	ARTISTS
	*/
	.title-artist{
		color: $secondarycolor;
		font-family: $basefont;
		@include font-size(24);
	}

	.filter-artist{
		margin: 1rem 0;

		.wrapper{
			display: table;
			width: 100%;
		}

		.col{
			display: table-cell;
			vertical-align: middle;

			& + .col{text-align: right;}
		}

		.list-vocal{
			display: inline-block;

			li{
				@extend .list-bullets;

				a{
		    		padding: .25em .45em;
		    	}
			}
		}

		.form-artist{
			position: relative;
			display: inline-block;
    		width: 295px;

    		input{
    			height: 37px;
    			padding: 0 0 0 40px;
    		}

    		button{
    			position: absolute;
    			top: 50%;
    			margin-top: -14px;
    			left: 8px;
    			cursor: pointer;
    		}
		}
	}

	/*
	*	PUBLISH
	*/
	.image--publish{
		background-color: $thirdcolor;

		.block{
			height: 100%;
		}

		.shell{
			position: absolute;
			bottom: 0;
			right: 5px;
			width: 35px;
			height: 50px;
			padding: .25em;
			text-align: center;
			background: $basecolor;
			color: $thirdcolor;
			@include font-size(22);
		}
	}

	/*
	*	CORPORACIONES
	*/
	.list--corporaciones{
		@include flexbox();
		@include flex-wrap(wrap);
		width: 100%;
		margin:3rem 0 5rem 0;

		li{
			display: inline-block;
			width: 20%;
			margin: 0 0 2rem 0;
			a {
			  display: inline-block;
			  text-align: center;
			}
		}


		img{
			display: block;
			margin: 0 auto .5rem auto;
		}

		.image{
			width: 205px;
			height: 120px;
		}
	}

	/*
	*	ALBUMS
	*/
	.album--carosuel{
		display: block;
		width: 100%;
		margin: 2rem 0;

		li{
			margin-right: .5rem;
		}

		a{
			display: inline-block;
			width: 125px;
			height: 100px;
			/* margin-left: .5rem; */

			.image{
				display: block;
				width: 100%;
				height: 100%;
			}
		}
	}

	/*
	*	PATRONATO
	*/
	.list--line{
		@include flexbox();
		@include flex-wrap(wrap);
		width: 100%;
		margin: 1.5rem 0;

		&.list--line__2{
			>li{
				width: 50%;
			}
		}

		&.list--line__3{
			>li{
				width: 33.3333%;
				@media only screen and (max-width: 425px) {
					width: 100%;
					padding: 0;
				}
			}
		}

		>li{
			display: inline-block;
			vertical-align: top;
			border-right: 2px solid $basecolor;
			@media only screen and (max-width: 425px) {
				border-right: none;
			}

			& + li{
				padding: 0 2rem;
			}

			&:last-child{border: 0;}
		}
	}

	/*
	*	PRENSA
	*/
	.panel--convene{
		display: table;
		width: 100%;
		margin-bottom: 2rem;

		aside, .body{
			display: table-cell;
			vertical-align: top;
		}

		aside{
			width: 21.875%;

			.wrapper{
				padding: 1.5rem 1rem;
				background: $thirdcolor;
			}

			.form--search{
				margin: 1rem 0 2rem 0;
			}
		}

		.body{
			width: 78.125%;
			padding-left: 2.25rem;

			.pagination{margin: 0 0 1rem 0 !important;}
		}
	}

		//LIST TAGS
		.list-tags{
			margin-bottom: 1rem;

			h5{margin-bottom: .5rem;}

			>li{
				margin-bottom: 1rem;
			}

			.sub-list{
				margin-left: 1rem;
			}
		}

		//MATERIAL DE INTERES
		.material--interes{
			background: $thirdcolor;
			padding: 1rem;
			
			>ul{
				width: 100%;

				>li{
					display: inline-block;
					width: 100%;
					margin-bottom: 1.5rem;

					&:last-child{
						margin-bottom: 0;
					}

					h5{
						margin-bottom: 1rem;
					}
				}
			}

			.sub--list{
				max-width: 335px;
				
				.elem{
					display: block;
					margin-bottom: .5rem;
					padding: .5rem 1rem;
					background: $white;
				}
			}

		}

	/*
	*	SEARCH GENERIC
	*/
	.list--resultSearch{
		width: 100%;

		li{
			display: table;
			width: 100%;
			//padding: .5em;
			margin-bottom: 1rem;
			
			&:hover{
				cursor: pointer;
				background: $thirdcolor;
			}

			.image, .content{
				display: block;
				vertical-align: top;
			}

			.image{
				width: 100%;

				& + .content{
					padding-left: 0rem;
                    @media only screen and (min-width: 481px) {
                      padding-left: 1.5rem;
                    }
				}
                img{
                  display: block;
                  //margin: auto;
                }
			}

            @media only screen and (min-width: 481px) {
              .image, .content{
                display: inline-block;
                width: calc(100% - 160px);
              }
              .image {
                width: 160px;
                height: auto;
                display: inline-block;
                float: left;
              }
            }
		}

		h3{margin:0 !important;}

		.category{
			color: $basecolor;
			@include font-size(14);
		}

		.category--italic{
			font-style: italic;
		}

	}

	/*
	*	SEARCH ACTIVIDADES
	*/
	.search-activity{
		display: table;
		width: 100%;
		margin: 2rem 0;
		background: $thirdcolor;

		>.col{
			display: table-cell;
			vertical-align: top;
			padding: 1rem;

			&:first-child{
				width:60%;
				padding-right: 0;
			}
		}

		.content--col{
			display: table;
			width: 100%;

			.col{
				display: table-cell;
				width: 50%;
				vertical-align: top;

				& + .col{
					padding-right: 1rem;
					border-right: 1px solid $secondarycolor;
				}
			}
		}

		h4{
			margin-bottom: .5rem;
			color: $secondarycolor;
			@include font-size(21);
		}

		.calendar{
			max-width: 275px;
			background: $white;
		}
	}

	/*
	*	PAGE STATICS
	*/

		/*
		*	COPORACION
		*/
		.list-corporacion{
			display: table;
			width: 100%;
			margin: 1rem 0 2rem 0;

			>li{
				display: table-cell;
				width: 50%;
				vertical-align: top;

				>ul{margin: 0 0 1rem 0;}
			}
		}

		/*
		*	INFO
		*/
		.copyrights{
			width: 95%;
			margin: 0 auto;
		}

		/*
		*	CONTACTO
		*/
		.page--contact{
			display: table;
			width: 100%;
			margin: 2em 0;

			.col{
				display: block;
				vertical-align: top;

				&:first-child{
                  width: 100%;
                  padding-right: 0;
				}
                @media only screen and (min-width: 769px) {
                  display: table-cell;
                  &:first-child{
                    width: 67.35%;
                    padding-right: 5.35%;
                  }
                }
			}

			.map{
				margin: 1rem 0;
			}

			.box{
				padding: 1rem;
				background: $thirdcolor;

				&.box-first{
					margin-bottom: 1rem;
				}
			}
		}

/*
*	VIDEO CONTAINER
*/
.video-container{
	position:relative;
	padding-bottom:56.25%;
	/* padding-top:30px; */
	height:0;
	overflow:hidden;

	&.mb{
		margin-bottom: 1em;
	}

	iframe, object, embed{
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
	}
}

/*
*	TAG
*/
.tags{
    position: relative;
    display: block;
    margin: 1rem 0;
    //padding-left: 2rem;
	@include font-size(15);

	a{
		padding: 3px;
		background: $lightergrey;
		margin-bottom: 3px;
		display: inline-block;
		//@include font-size(12);
		//text-transform: uppercase;
	}

	//&:before, &:after{
	//	content: "";
	//	position: absolute;
	//	top: 50%;
	//	margin-top: -7px;
	//}
	//
	//&:before{
	//	left: 0;
	//	width: 18px;
	//	height: 14px;
	//	background: $basecolor;
	//}
	//
	//&:after{
	//	left: 18px;
	//	width: 0;
	//	height: 0;
	//	border-style: solid;
	//	border-width: 7px 0 7px 8px;
	//	border-color: transparent transparent transparent $basecolor;
	//}
}

/*
*	VIEW MORE
*/
.view-more{
	padding: .5rem 0;

	&:after{
		content: "";
		display: inline-block;
		background: url("#{$img-dir}/template/sprites.png");
		background-position: -7px -26px;
		width: 11px;
		height: 10px;
		margin-left: .25rem;
	}
}

/*
*	DATAPICKER
*/
.ui-widget-content.search-filter{
	max-width: 235px;
	margin: 10px 0 0 0;
	padding: .5rem;
	border: 1px solid $secondarycolor;
	background: $white;
	border-radius: 0;
}

/*
*	POPUPS
*/
.popup{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background: $blackTransparent;
	@include opacity(0);
	@include transition(opacity .5s ease-in-out);
	z-index: -1;

	&.active{
		@include opacity(1);
		@include transition(opacity .5s ease-in-out);
		z-index: 9999;
	}

	.wrapper{
		display: table;
		padding: 2em;
		margin: 0 auto;
		background: $white;
		@include vertical-align();
	}
	
	&.popup-zoom{
		.wrapper{
			width: 95%;
			max-width: $pagemaxwidth;
			padding: 1em 3em;
			overflow: hidden;
		}
	}
}

	.flagMessage{
		&.active{
			@include opacity(1);
			@include transition(opacity .5s ease-in-out);
			z-index: 9999;

			.content{
				top: 50%;
				@include transition(all 1s ease-in-out);
			}
		}

		.wrapper{
			display: table;
			padding: 2em;
			margin: 0 auto;
			background: $white;
			text-align: center;
			@include vertical-align();
			

			.icon{
				display: block;
				margin: .25em auto .75em auto;
				@include font-size(48);
			}

			p{
				/* max-width: 500px; */
				margin-bottom: 3em;
				letter-spacing: 0.08em;
				@include font-size(16);
			}
			
			.close-cross{
				position: absolute;
				top: 10px;
				right: 5px;
				width: 25px;
				height: 25px;
				color: $secondarycolor;
				text-align: center;
				
				.icon{
					margin: 0;
					@include font-size(18);
				}
			}
		}

		.content{
			position: absolute;
			top: -120%;
			left: 50%;
			width: 450px;
			margin-left: -225px;
			text-align: center;
			color: $secondarycolor;
			@include border-radius(10px,10px,10px,10px);
			@include transition(all 1s ease-in-out);

			
			.wrapper{
				position:relative;
				width: 100%;
				height: 100%;
				padding: 2em;
			}
		}
	}

	//MESSAGE
	.message{
		display: inline-block;
		width: 100%;
		margin: 1rem 0 1.5rem 0;
		padding: 1rem;
		color: $secondarycolor;
		background: $thirdcolor;

		p{margin: 0;}
	}

/*
*	SCROLL BUTTON
*/
.scrollUp{
	position: fixed;
	bottom: 25px;
	right: 25px;
	width: 40px;
	height: 40px;
	background: url('#{$img-dir}/template/sprites/arrowUpt.png');
	background-repeat: no-repeat;
	background-size: 70% 70%;
	background-position: center center;
	background-color: $basecolor;
	color: $white;
	text-align: center;
	@include opacity(.8);
	@include scale(0);
	@include border-radius(50%,50%,50%,50%);
	@include transition(all .5s ease-in-out);

	&.active{
		@include scale(1);
		@include opacity(1);
		@include transition(all .5s ease-in-out);
	}

}

/*
*	COOKIES MESSAGE
*/
.cookiesMessage{
	position: fixed;
	bottom: -100%;
	left: 0;
	right: 0;
	padding: .5em;
	text-align: center;
	background: $white;
	border-bottom: 1px solid $secondarycolor;
	@include transition(all 1s ease-in-out);

	&.active{
		bottom: 0;
		@include transition(all 1s ease-in-out);
	}

	p{
		color: $secondarycolor;
		padding: 5px 0;
		margin: 0;
		@include font-size(14);

		a{
			display: inline;
			margin: 0 .5em;
			color: $secondarycolor;
			font-weight: bold;
			font-style: normal;
  			letter-spacing: 2px;
			text-decoration: underline;

		}
	}
}

/*
*	KEYFRAMES
*/
@-moz-keyframes spin {
    from { -moz-transform: rotate(0deg); }
    to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}


/*
*	RECORRIDO
*/
.recorrido-align{
  margin: 25px 0;
}

.recorrido-title{
  	font-weight: bold;
  	min-height: 60px;
}

.list-elem--colection .btn--recorrido{
  	margin-top: 0;
}

.list-elem--colection .btn--expo{
	margin: 5px 0 25px;
}


.rec-row{
  	@include clearfix;
}

.rec-col{
  	width: 50%;
  	float: left;

  	.rec-inner{
	  	padding-left: 10px;
	}
}

/*
*	ADVERTENCIA LEGAL
*/

.icono-balance{
	position: relative;
	padding-left: 40px;
	display: block;

	&:after{
		content: "";
		position: absolute;
		top: 50%;
		left: 0px;
		margin-top: -20px;
		background: url("#{$img-dir}/template/sprites.png");
		background-position: -158px -103px;
		margin-right: 10px;
		width: 31px;
		height: 31px;
	}
}

/*
*	PUBLICACIONES
*/
.small-txt{
	@include font-size(13);
}

/*
*	PUBLICACIONES
*/
#sync1 .item{
	text-align: center;
}
#sync2 .item{
	background: $greyMenu;
	padding: 1px;
	margin: 5px;
	text-align: center;
	cursor: pointer;
}
#sync2 .synced .item{
	background: $brownLight;
}

/* clearfix */
.owl-main-wrapper{
	overflow: hidden;
	position: relative;
	width: 512px;
}

.owl-top-carousel-wrapper{

}

.owl-bottom-carousel-wrapper{

}

.owl-carousel {
	.owl-wrapper {
		&:after {
			content: ".";
			display: block;
			clear: both;
			visibility: hidden;
			line-height: 0;
			height: 0;
		}
		display: none;
		position: relative;
		-webkit-transform: translate3d(0px, 0px, 0px);
	}
	display: none;
	position: relative;
	width: 100%;
	-ms-touch-action: pan-y;
	.owl-wrapper-outer {
		overflow: hidden;
		position: relative;
		width: 100%;
		&.autoHeight {
			-webkit-transition: height 500ms ease-in-out;
			-moz-transition: height 500ms ease-in-out;
			-ms-transition: height 500ms ease-in-out;
			-o-transition: height 500ms ease-in-out;
			transition: height 500ms ease-in-out;
		}
	}
	.owl-item {
		float: left;
	}
}

/* display none until init */

.owl-controls {
	.owl-page, .owl-buttons div {
		cursor: pointer;
	}
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

///* mouse grab icon */
//
//.grabbing {
//	cursor: url(grabbing.png) 8 8, move;
//}

/* fix */

.owl-carousel {
	.owl-wrapper, .owl-item {
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility: hidden;
		-ms-backface-visibility: hidden;
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
	}
}
.carousel-exposicion {
	display: block;
	overflow: hidden;
	.template_body {
		display: block;
		overflow: hidden;
	}
	.col {
		display: block;
		float: left;
		overflow: hidden;
	}
	.wrapper {
		width: 512px !important;
	}
	.carousel {
		width: 100%;
	}
	li.item-carousel {
		height: auto !important;
		img {
			width: 100%;
		}
	}
}


nav .menu li {
  a {
    display: inline-block;
    width: 79%;
    &.menu-hover-expand {
      display: inline-block;
      width: 10%;
      text-align: center !important;
    }

    @media only screen and (min-width: 443px) {
      width: 89%;
    }
  }
  &:last-child a{
    padding: .5em 0;
  }
  @media only screen and (min-width: 769px) {

    a {
      display: block;
      width: auto;
      &.menu-hover-expand {
        display: none;
      }
    }
  }
}
nav {
  .submenu-mobile {
    position: relative;
    ul {
      &:before {
        width: 0;
        height: 0;
        content: " ";
        position: absolute;
        top: -4px;
        left: 25px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;

        border-bottom: 5px solid #E3E1E0;
        @media only screen and (min-width: 769px) {

        }
      }

      &.active {
        background: #E3E1E0 !important;
        overflow: visible;
      }
    }
    &.active {
      overflow: visible;
    }
  }
}
.sugerencias, .contacto {
  .row--form {
    display: block;
    margin-bottom: 0;
    .privacidad{
      margin: 1em 0;
    }
    .wrapper {
      flex-direction: column;
      label {
        width: 100%;
        text-align: left;
        padding-left: 0;
      }
      > label.middle {
        padding-left: 0;
      }
      &.inline.inline-mh {
        margin-left: 0;
      }
    }

  }
  @media only screen and (min-width: 769px) {
    .row--form {
      display: inline-flex;
      margin-bottom: 1rem;
      .privacidad{
        margin: 0;
      }
      .wrapper {
        flex-direction: row;
        label {
          width: auto;
          padding: 0 1rem;
          padding-left: 1rem;
        }
        > label.middle {
          padding-left: 1rem;
        }
        &.inline.inline-mh {
          margin-left: 180px;
        }
      }
    }
  }
  .form-inline .row--form {
sky
    .wrapper {
      label[for="legal-notice"]{
        width: 85%;
        padding: 0;
        @media only screen and (min-width: 769px) {
          width: 85%;
        }
      }
      .inline.inline-mh {
        padding: 1em 0;
        margin-left: 0;
        @media only screen and (min-width: 769px) {
          margin-left: 180px;
        }
      }
    }
  }
}

.col #link-search img, .image #link-search img, .col button > img, .image button > img  {
	width: 100%;
	@media only screen and (min-width: 769px) {
		width: 24px !important;
	}
}

.col img, .image img{
  width: 100%;
  @media only screen and (min-width: 769px) {
    width: auto;
  }
}
section {
  .ui-datepicker table {
    font-size: .7em;
    @media only screen and (min-width: 769px) {
      font-size: .9em;
    }
  }
}

.ver-actividad0{
  display: none;
  @media only screen and (min-width: 769px) {
    display: inline-block;
  }
}
.ver-actividad2{
  display: inline-block;
  @media only screen and (min-width: 769px) {
    display: none;
  }
}

.logo--footer a{
  width: 75%;
  display: block;
}


/*
*	SOCIAL
*/
.social-network span.icon-twitter{
	@media only screen and (max-width: 769px) {
		margin-left: 30px;
	}
}

/*
 *	EXPOSICIONES TEMPORALES
 */
.exposicion-item {
	height: 725px;
	img {
		max-height: 471px;
	}
}

/*
 * VIÑETAS DOBLE FLECHA
 */
.arrow-bullet {
	background: url("#{$img-dir}/template/sprites.png");
	background-position: -28px -4px;
	width: 10px;
	height: 16px;
	white-space: nowrap;
	.arrow-bullet-text {
		padding-left: 15px;
	}
}

/*
 * TAGS
 */
.newtag {
	background-color: #ebe9ea;
	color: #7d7167;
	padding: 2px 5px;
	width: auto;
	display: inline;
	&:hover {
		background-color: #6f645b;
		color: white;
	}
}

/*
 * FIXES
 */
.undo-top-paragraph {
	margin-top: -20px;
}

.contact .checkbox-custom.left {
	width: 35px;
}

/*
 * VARIOS
 */
.pointer {
	cursor: pointer;
}
