
/*------------------------------------*\
    CUSTOM FORM
\*------------------------------------*/


	.form-search{
		display: table;
		width: 100%;
		margin: .5em 0;

		.col{
			display: table-cell;
			vertical-align: middle;

			& + .col{
				text-align: right;
			}
		}

		.link-search, select, input{
			display: inline-block;
		}

		select{
			max-width: 180px;
		}

		.searchText{
			width: 70%;
			background: transparent;
		}
	}

	.form-newsletter{
		margin: 0;

		.row{
			margin-bottom: 1em;
		}

		input{margin: 0;}

		.item--email{
				position: relative;
				width: 100%;
				display: table-cell;
		  		margin: 0;
		  		padding-right: 5px;
		  		vertical-align: top;

			.response{
				display: block;
				position:absolute;
				top: 0;
				right: 5px;
				width: 24px;
				height: 24px;
				margin: 7px;
				line-height: 1.15;
				@include font-size(24);
			}

		}
	}

	.form--filter{
		.row--form{
			display: table;
			width: 100%;
			margin-bottom: .5em;

			.item--form{
				display: table-cell;
				vertical-align: top;

				&:nth-child(2){width: 40% !important;}
			}
		}
	}

	.form-inline{

		&.contact{
			label{
				min-width: 180px;
			}
		}

		&.contact-alta{
			.select--custom{
				width: 100%;
			}
		}

		label{
			padding-right: 1rem;
			vertical-align: middle;
		}

		.row--form{
			display: inline-flex;
			flex-direction: row;
			width: 100%;
			margin-bottom: 1rem;

			.item--form{
				flex: 1 0 50%;
			}

			.wrapper{
				display: inline-flex;
				flex-direction: row;
				width: 100%;

				>label{
					flex: 0 0 auto;
					line-height: 2.5;

					&.middle{
						padding: 0 1rem;
					}
				}

				.inline{
					flex: 1 0 auto;

					&.inline-mh{
						margin-left: 180px;
					}
				}
			}
		}

	}

	.form--activity{
		width: 100% !important;

		.item--form{
			width: 100%;
		}

		.select--custom{
			width: 100%;
		}
	}

/*
*	COMMON FORM
*/
.form--custom{
	display:table;
	width: 95%;
	margin: 0 auto;

	.row--form{
		display: table;
  		width: 100%;
  		margin-bottom: .5rem;

  		&.row--form--2{
  			.item--form{
  				display: table-cell;
  				width: 50%;
  				vertical-align: top;
  			}
  		}
	}

	.item--form{
		display: table;
		margin-bottom: .5em;
	}
}
	
	.item--form{
		position: relative;

		&.calendar-icon{
			
			input{cursor: pointer;}

			.icon{
				display: block;
				position: absolute;
				top: 50%;
				margin-top: -13px;
				right: 15px;
				background: url("#{$img-dir}/template/sprites.png");
				background-position: -563px -15px;
				width: 22px;
				height: 22px;
			}
		}
	}

	.item-form-checkbox{
		display: table;
		width: 100%;
		margin-bottom: .5em;

		> .checkbox-custom, > label{
			display: table-cell;
			vertical-align: top;
		}

		> label{
			color: $secondarycolor;
			font-weight: normal;
			font-style: italic;
			padding-left: 1em;
			@include font-size(16);
		}
	}

	/*
	*	CUSTOM FILE
	*/
	.item-file{
		bottom: 20px;

		.btn-file{
			position: relative;
			display: inline-block;
			width:100%;
			padding: 5px 175px 5px 15px;
			text-align: right;
			color: $basecolor;
			background: $white;
			border: 2px solid $white;
			font-style: italic;
			line-height: 2;
			cursor: pointer;

			span{
				font-weight: normal;
				letter-spacing: 0;
				@include font-size(16);
			}

			.selectImage{
				position: absolute;
				top: 0;
				right: 0;
				height: 42px;
				width: 160px;
				text-align: center;
				color: $white;
				background: $basecolor;
				line-height: 2.5;
				letter-spacing: 0.1em;
				border: 2px solid $white;
			}

			#file-upload {
				position: absolute;
				left: -9999px;
			}
		}
	}

	/*
	*	CUSTOM RADIO
	*/

	.custom-radio{
		display: block;
		margin: .5em .5em 0 0;
		float: left;

		input[type="radio"]{
			display: none;

			&:checked ~ label > span{
				&:before{
					background: $basecolor;
					@include box-shadow(0px 0px 2px 0px rgba(50, 50, 50, 0.75));
				}
			}
		}

		label{
			display: block;

			span{
				position:relative;
				display: inline-block;
				width: 20px;
				height: 20px;
				margin-right: .5em;
				vertical-align: middle;
				background: $white;

				&:before{
					content: "";
					position:absolute;
					top: 50%;
					margin-top: -7px;
					left: 50%;
					margin-left: -7px;
					right: 0;
					bottom: 0;
					width: 14px;
					height: 14px;
				}
			}
		}	
	}

	/*
	*	CUSTOM CHECKBOX
	*/
	.checkbox-custom{
		position:relative;
		width: 20px;
		height: 20px;
		overflow: hidden;
		@include transition(all .3s ease-in-out);

		&.left{
			float: left;
			margin-right: 1rem;
		}

		>label{
			position:absolute;
			top: 5px;
			left: 0;
			min-width: 20px !important;
			width: 20px;
			height: 20px;
			background: #FFF;
			border: 1px solid $white;

			&.error{
				background: lighten($color-error, 30%);
				border: 1px solid $color-error;
				@include transition(all .3s ease-in-out);
			}
		}

		> input[type="checkbox"]{
			position:absolute;
			top: 100%;
			left: 0;
			visibility: hidden;

			&:checked + label:before{
				content: '✔';
				display: block;
				width: 100%;
				height: 100%;
				color: $blacklight;
				text-align: center;
				line-height: 1.35;
			}

		}

	}

textarea.field{
	height: 100%;
	padding: 10px 0 0 22px;
}
.field{
	height: 38px;
	padding: 0 0 0 22px;

	&.error{
  		border:$inputborderwidth solid $color-warning;
	}
}

.field-input{

	&.error{
		border:$inputborderwidth solid $color-warning;
	}
}

/*------------------------------------*\
    $FORMULARIOS
\*------------------------------------*/

form {
	/* margin : 0 0 $baselineheight 0; */

	fieldset {
		margin-bottom : $baselineheight;
		padding : 0;
		border-width : 0;
	}

	legend {
		display : block;
		width : 100%;
		margin-bottom : $baselineheight * 2;
		padding : 0;
		color : $textcolor;
		border : 0;
		border-bottom : $inputborderwidth $inputborderstyle $inputborder;
		white-space : normal;

		small {
			@include font-size(14);
		color : $lightgrey;
		}
	}

	p {
		margin-bottom : $baselineheight / 2;
	}

	ul {
		list-style-type : none;
		margin : 0 0 $baselineheight 0;
		padding : 0;
	}

	br {
		display : none;
	}
}

label,
input,
select,
textarea {
	vertical-align : baseline;
	*vertical-align : middle;
	@include font-size(16);
	@include transition(all .3s ease-in-out);

	&.error{
		background: lighten($color-error, 30%);
		border: 2px solid $color-error;
		@include transition(all .3s ease-in-out);
	}
}

input,
button,
select,
textarea {
	font-family : $basefont;
	@include box-sizing(border-box);
}

label {
	display: inline-block;
	margin-bottom : $baseline / 8;
	font-weight : bold;
	color : $secondarycolor;
	cursor : pointer;

	input,
	textarea,
	select {
		display : block;
	}
}

input,
textarea,
select {
	display : inline-block;
	width : 100%;
	height: 29px;
	padding : 0 0 0 10px;
	margin-bottom : $baselineheight / 4;
	background-color : $white;
	border : $inputborderwidth $inputborderstyle $inputborder;
	color : $secondarycolor;

	/* &:hover {
		border-color : $inputhover;
	} */
}

.input-mini {
	width : 60px;
}

.input-small {
	width : 90px;
}

input[type="image"],
input[type="checkbox"],
input[type="radio"] {
	width : auto;
	height : auto;
	padding : 0;
	margin : 3px 0;
	*margin-top : 0;
	line-height : normal;
	cursor : pointer;
	border : 0 \9;
}

input[type="checkbox"],
input[type="radio"] {
	@include box-sizing(border-box);
	padding : 0;
	*width : 13px;
	*height : 13px;
}

input[type="image"] {
	border : 0;
	@include box-shadow(none);
}

input[type="file"] {
	width : auto;
	padding : initial;
	line-height : initial;
	border : initial;
	background-color : transparent;
	background-color : initial;
	@include box-shadow(none);
}

input[type="button"],
input[type="reset"],
input[type="submit"] {
	width : auto;
	height : auto;
	cursor : pointer;
	*overflow : visible;
}

select,
input[type="file"] {
	*margin-top : 4px;
	/* line-height : 28px; */
	@include font-size(16);
}

select {
	background-color : $inputbackground;
	@include font-size(16);
}

select[multiple],
select[size] {
	height : auto;
}

textarea {
@include resize(vertical);
	height : auto;
	overflow : auto;
	vertical-align : top;
}

input[type="hidden"] {
	display : none;
}

.radio,
.checkbox {
	padding-left : 18px;
	font-weight : normal;
}

.radio input[type="radio"],
.checkbox input[type="checkbox"] {
	float : left;
	margin-left : -18px;
}

.radio.inline,
.checkbox.inline {
	display : inline-block;
	padding-top : 5px;
	margin-bottom : 0;
	vertical-align : middle;
}

.radio.inline + .radio.inline,
.checkbox.inline + .checkbox.inline {
	margin-left : 10px;
}

// disabled

input[disabled],
select[disabled],
textarea[disabled],
input[readonly],
select[readonly],
textarea[readonly] {
	@include opacity(.5);
	cursor : not-allowed;
}

// focus and active

input:focus,
textarea:focus {
	border-color : $inputfocus;
	outline : 0;
	outline : thin dotted \9;
}

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus,
select:focus {
	@include box-shadow(none);
}

::-webkit-input-placeholder {
	@include font-size(16);
	color: $greyDark;
}

input:-moz-placeholder {
	@include font-size(16);
	color: $greyDark;
}

// help text

.help-block,
.help-inline {
	color : $greyDark;
}

.help-block {
	display : block;
	margin-bottom : $baselineheight / 2;
}

.help-inline {
	display : inline-block;
	vertical-align : middle;
	padding-left : 5px;
}

// .form-inline

.form-inline input,
.form-inline textarea,
.form-inline select {
	display : inline-block;
	margin-bottom : 0;
}

.form-inline label {
	display : inline-block;
}

.form-inline .radio,
.form-inline .checkbox,
.form-inline .radio {
	padding-left : 0;
	margin-bottom : 0;
	vertical-align : middle;
}

.form-inline .radio input[type="radio"],
.form-inline .checkbox input[type="checkbox"] {
	float : left;
	margin-left : 0;
	margin-right : 3px;
}

// .form-search

.form-search input,
.form-search textarea,
.form-search select {
	display : inline-block;
	margin-bottom : 0;
}

.form-search .search-query {
	padding-left : 14px;
	padding-right : 14px;
	margin-bottom : 0;
}

.form-search label {
	display : inline-block;
}

.form-search .radio,
.form-search .checkbox,
.form-inline .radio {
	padding-left : 0;
	margin-bottom : 0;
	vertical-align : middle;
}

.form-search .radio input[type="radio"],
.form-search .checkbox input[type="checkbox"] {
	float : left;
	margin-left : 0;
	margin-right : 3px;
}


/**
* VALIDATE FIELD
*
*/

input[value="*"] {
    background-color: red;
}

input:required:focus:invalid {
  	border:$inputborderwidth solid $color-warning;
}
input:required:focus:valid {
  	border:$inputborderwidth solid $color-success;
}

/*------------------------------------*\
    CUSTOM FIELD
\*------------------------------------*/
.submitSearch{
	position: absolute;
	top: 2px;
	display: inline-block;
	background: url(#{$svg-dir}/icon-search.svg) no-repeat;
	background-size: 32px 32px;
	background-position: center center;
	height: 20px !important;
	width: 20px !important;
	margin: 0;
	border: 0;
	z-index: 3;

	&.submitSearch--mobile{
		cursor: pointer;
		right: 0;
		height: 30px !important;
		width: 30px !important;
		background: url(#{$svg-dir}/icon-search.svg) no-repeat;
	}

	&:hover{
		border-color: $white;
	}
}

.checkbox-custom{
	position:relative;
	width: 20px;
	height: 20px;
	overflow: hidden;

	>label{
		position:absolute;
		top: 0;
		left: 0;
		width: 20px;
		height: 20px;
		background: #FFF;
		border: 1px solid $basecolor;
		@include border-radius(0, 0, 0, 0);
	}

	> input[type="checkbox"]{
		position:absolute;
		top: 100%;
		left: 0;
		visibility: hidden;

		&:checked + label:before{
			content: "✔";
			position: absolute;
			top: 50%;
			left: 50%;
			margin-left: -7px;
			margin-top: -10px;
			display: block;
			width: 14px;
			height: 14px;
			color: $basecolor;
		}

	}

}

.item-file{
	opacity:0;
	@include transition(all .3s ease-in-out);
}

.item-file-active{
	opacity:1;
	@include transition(all .3s ease-in-out);
}

#file-upload {
    position: absolute;
    left: -9999px;
}

label[for="file-upload"]{
	display:inline-block;
	width:100%;
	cursor:pointer;
	@include border-radius(2px, 2px, 0, 0);

}

#filename{
	padding:0.5em;
	height: 42px;
	float:left;
	width:70%;
	white-space: nowrap;
	overflow:hidden;
	background:$white;
	color:$blacklight;
	line-height: 2;
	@include font-size(14);
	@include border-radius( 0, 0, 2px, 2px);

	&:hover{
		cursor: pointer;
	}
}

.select--custom{
	display: inline-block;
	position:relative;
    width: 235px;
    min-width: 235px;
	height: 29px;
	margin-bottom: 5px;

	&.field{
		height: 38px;
		padding: 0;

		select{
			height: 38px;
			padding: 0;
		}

		.placeholder{
			padding: 0 0 0 22px;
			line-height: 2.25;

			&:after{
				height: 38px;
				width: 38px;
			}
			.text{
				height: 38px;
			}
		}
	}

	select{
		opacity: 0;
		z-index: 10;
		position:relative;
		display: block;
		cursor: pointer;
		height: 29px;
	}

	.placeholder{
		display: inline-block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 0 0 0 10px;
		line-height: 1.65;
		color: $secondarycolor;
		background-color: $white;
		border: 1px solid $secondarycolor;
		@include font-size(16);

		&:after{
			content: "";
			position: absolute;
			display: block;
			top: -1px;
			right: 0;
			height: 29px;
			width: 29px;
			border-left: 1px solid $secondarycolor;
			background: url(#{$img-dir}/template/sprites/arrow-select.png) no-repeat;
			background-position: center center;
		}

		.text{
			position:relative;
			width: 100%;
			height: 1em;
		}

		&:hover{
			cursor: pointer;
		}
	}
}

.loading-form{
	display:none;
	float: right;
	margin: .5em;
}

#response{
	color: $white !important;
}

/*
*	RESPONSER
*/
.response{
	vertical-align: middle;
	text-align: center;

	&.icon-checkmark{
		color: $color-success;
	}

	&.icon-cross{
		color: $color-warning;
	}

	&.icon-spinner2{
		@include animation(spin 4s infinite linear);
	}
}

