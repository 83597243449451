/*------------------------------------*\
    $MIXINS
\*------------------------------------*/
@mixin media-query-bp($breakpoint, $query1: min, $query2: width, $target: "") {

	// Check if media queries are supported
	@if $media-queries-supported == true {
		@if $target != "" {
			@media #{$target} and (#{$query1}-#{$query2}: #{$breakpoint}) {
				@content;
			}
		}
		@else {
			@media (#{$query1}-#{$query2}: #{$breakpoint}) {
				@content;
			}
		}
	}
	@else {
		#{$no-mq-classname} & {
			@content;
		}
	}
}


@mixin font-size($font-size: 18){
    font-size:($font-size)*1px;
    font-size:($font-size / $basefontsize)*1rem;
}

  /**
  * Centrar y dar un ancho máximo igual a $pagemaxwidth.
  */
@mixin max-width($max-width : $pagemaxwidth)  {
	margin-left:auto;
	margin-right:auto;
	max-width: $max-width;
	width:100%;
}


// gradients
@mixin horizontal($startColor : $white, $endColor : $lightergrey) {
	background-color: $endColor;
	background-image  : -webkit-gradient(linear, 0 0, 100% 0, from($startColor), to($endColor)); // Safari 4+, Chrome 2+
	background-image  : -webkit-linear-gradient(left, $startColor, $endColor);                   // Safari 5.1+, Chrome 10+
	background-image  : -moz-linear-gradient(left, $startColor, $endColor);                      // FF 3.6+
	background-image  : -ms-linear-gradient(left, $startColor, $endColor);                       // IE10
	background-image  : -o-linear-gradient(left, $startColor, $endColor);                        // Opera 11.10
	background-image  : linear-gradient(left, $startColor, $endColor);                           // W3C
	background-repeat : repeat-x;
}

@mixin vertical($startColor : $white, $endColor:  $lightergrey) {
	background-image  : -webkit-gradient(linear, 0 0, 0 100%, from($startColor), to($endColor)); // Safari 4+, Chrome 2+
	background-image  : -webkit-linear-gradient(top, $startColor, $endColor);                    // Safari 5.1+, Chrome 10+
	background-color  : $endColor;
	background-image  : -moz-linear-gradient(top, $startColor, $endColor);                       // FF 3.6+
	background-image  : -ms-linear-gradient(top, $startColor, $endColor);                        // IE10
	background-image  : -o-linear-gradient(top, $startColor, $endColor);                         // Opera 11.10
	background-image  : linear-gradient(top, $startColor, $endColor);                            // W3C
	background-repeat : repeat-x;
}

@mixin directional($startColor : $white, $endColor : $lightergrey, $deg : 45deg) {
	background-color  : $endColor;
	background-image  : -moz-linear-gradient($deg, $startColor, $endColor);                     // FF 3.6+
	background-image  : -ms-linear-gradient($deg, $startColor, $endColor);                      // IE10
	background-image  : -webkit-linear-gradient($deg, $startColor, $endColor);                  // Safari 5.1+, Chrome 10+
	background-image  : -o-linear-gradient($deg, $startColor, $endColor);                       // Opera 11.10
	background-image  : linear-gradient($deg, $startColor, $endColor);                          // W3C
	background-repeat : repeat-x;
}

// .bordered(COLOR, COLOR, COLOR, COLOR);
@mixin bordered($top-color: #eee, $right-color: #eee, $bottom-color: #eee, $left-color: #eee) {
	border-top : solid 1px $top-color;
	border-left : solid 1px $left-color;
	border-right : solid 1px $right-color;
	border-bottom : solid 1px $bottom-color;
}

// .border-radius(VALUE,VALUE,VALUE,VALUE);
@mixin border-radius($topright: 0, $bottomright: 0, $bottomleft: 0, $topleft: 0) {
	-webkit-border-top-right-radius : $topright;
	-webkit-border-bottom-right-radius : $bottomright;
	-webkit-border-bottom-left-radius : $bottomleft;
	-webkit-border-top-left-radius : $topleft;
	-moz-border-radius-topright : $topright;
	-moz-border-radius-bottomright : $bottomright;
	-moz-border-radius-bottomleft : $bottomleft;
	-moz-border-radius-topleft : $topleft;
	border-top-right-radius : $topright;
	border-bottom-right-radius : $bottomright;
	border-bottom-left-radius : $bottomleft;
	border-top-left-radius : $topleft;
	-webkit-background-clip : padding-box;
	-moz-background-clip : padding;
	background-clip : padding-box;
}

// .box-shadow(HORIZONTAL VERTICAL BLUR COLOR))
@mixin box-shadow($shadow: 0 1px 3px rgba(0,0,0,.25)) {
	-webkit-box-shadow : $shadow;
	-moz-box-shadow : $shadow;
	box-shadow : $shadow;
}

// .drop-shadow(HORIZONTAL, VERTICAL, BLUR, ALPHA);
@mixin drop-shadow($x-axis: 0, $y-axis: 1px, $blur: 2px, $alpha: 0.1) {
	-webkit-box-shadow : $x-axis $y-axis $blur rgba(0, 0, 0, $alpha);
	-moz-box-shadow : $x-axis $y-axis $blur rgba(0, 0, 0, $alpha);
	box-shadow : $x-axis $y-axis $blur rgba(0, 0, 0, $alpha);
}

// .opacity(VALUE);
@mixin opacity($opacity : .5) {
	-webkit-opacity : $opacity;
	-moz-opacity : $opacity;
	opacity : $opacity;
}

// .rotate(VALUEdeg);
@mixin rotate($deg) {
	-webkit-transform : rotate($deg);
	-moz-transform : rotate($deg);
	-ms-transform : rotate($deg);
	-o-transform : rotate($deg);
	transform : rotate($deg);
}

// .rotateY(VALUEdeg);
@mixin rotateY($deg) {
	-webkit-transform : rotateY($deg) !important;
	-moz-transform : rotateY($deg) !important;
	-ms-transform : rotateY($deg) !important;
	-o-transform : rotateY($deg) !important;
	transform : rotateY($deg) !important;
}

// .rotateX(VALUEdeg);
@mixin rotateX($deg) {
	-webkit-transform : rotateX($deg);
	-moz-transform : rotateX($deg);
	-ms-transform : rotateX($deg);
	-o-transform : rotateX($deg);
	transform : rotateX($deg);
}

// .scale(VALUE);
@mixin scale($ratio) {
	-webkit-transform : scale($ratio);
	-moz-transform : scale($ratio);
	-ms-transform : scale($ratio);
	-o-transform : scale($ratio);
	transform : scale($ratio);
}

// .skew(VALUE, VALUE);
@mixin skew($x: 0, $y: 0) {
	-webkit-transform : skew($x, $y);
	-moz-transform : skew($x, $y);
	-ms-transform : skew($x, $y);
	-o-transform : skew($x, $y);
	transform : skew($x, $y);
}

// .transition(PROPERTY DURATION DELAY(OPTIONAL) TIMING-FINCTION);
@mixin transition($transition) {
	-webkit-transition : $transition;
	-moz-transition : $transition;
	-ms-transition : $transition;
	-o-transition : $transition;
	transition : $transition;
}

// .translate(VALUE, VALUE)
@mixin transform-origin($value: center, $value2: center) {
	-webkit-transform-origin:$value, $value;
	-moz-transform-origin:$value, $value;
  	transform-origin:$value, $value;
}

// .translate(VALUE, VALUE)
@mixin translate($x: 0, $y: 0) {
	-webkit-transform : translate($x, $y);
	-moz-transform : translate($x, $y);
	-ms-transform : translate($x, $y);
	-o-transform : translate($x, $y);
	transform : translate($x, $y);
}

@mixin translateX($value: 0) {
  -webkit-transform: translateY($value);
  -ms-transform: translateY($value);
  transform: translateY($value);
}

@mixin translateY($value: 0) {
  -webkit-transform: translateY($value);
  -ms-transform: translateY($value);
  transform: translateY($value);
}

@mixin translate3d($x: 0, $y: 0, $z: 0) {
	-webkit-transform : translate($x, $y, $z);
	-moz-transform : translate($x, $y, $z);
	-ms-transform : translate($x, $y, $z);
	-o-transform : translate($x, $y, $z);
	transform : translate($x, $y, $z);
}

@mixin filter($val: 5px){
	-moz-filter: blur($val);
	-o-filter: blur($val);
	-ms-filter: blur($val);
	-webkit-filter: blur($val);
	filter: blur($val);	
}

// .background-alpha(VALUE VALUE);
@mixin background-alpha($color: $white, $alpha: 1) {
	background-color : hsla(hue($color), saturation($color), lightness($color), $alpha);
}

// .background-size(VALUE VALUE);
@mixin background-size($size){
	-webkit-background-size : $size;
	-moz-background-size : $size;
	-o-background-size : $size;
	background-size : $size;
}

// .background-clip(VALUE); (border-box, padding-box, content-box)
@mixin background-clip($clip) {
	-webkit-background-clip : $clip;
	-moz-background-clip : $clip;
	background-clip : $clip;
}

// .box-sizing(VALUE); (border-box, padding-box, content-box)
@mixin box-sizing($boxsize: border-box) {
	-webkit-box-sizing : $boxsize;
	-moz-box-sizing : $boxsize;
	-ms-box-sizing : $boxsize;
	box-sizing : $boxsize;
}


// .resize(VALUE) (none, both, horizontal, vertical, inherit)

@mixin resize($direction: both) {
	resize : $direction;
	overflow : auto;
}

// .userselect(VALUE) (all, element, none, text)

@mixin user-select($select) {
	-webkit-user-select : $select;
	-moz-user-select : $select;
	-o-user-select : $select;
	user-select : $select;
}

//CLEARFFIX
@mixin clearfix() {
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}

//VERTICAL ALIGN
@mixin vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

//KEYFRAMES
@mixin keyframes($animation-name) {
  @-webkit-keyframes $animation-name {
    @content;
  }
  @-moz-keyframes $animation-name {
    @content;
  }  
  @-ms-keyframes $animation-name {
    @content;
  }
  @-o-keyframes $animation-name {
    @content;
  }  
  @keyframes $animation-name {
    @content;
  }
}

//ANIMATE
@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};      
}


// .columns(250px, 0, 24px, COLOR, solid, 1px)

@mixin columns($colwidth: 250px, $colcount: 0, $colgap: 50px, $columnRuleColor: #eee, $columnRuleStyle: solid, $columnRuleWidth: 1px) {
	-webkit-column-width : $colwidth;
	-webkit-column-count : $colcount;
	-webkit-column-gap : $colgap;
	-webkit-column-rule-color : $columnRuleColor;
	-webkit-column-rule-style : $columnRuleStyle;
	-webkit-column-rule-width : $columnRuleWidth;
	-moz-column-width : $colwidth;
	-moz-column-count : $colcount;
	-moz-column-gap : $colgap;
	-moz-column-rule-color : $columnRuleColor;
	-moz-column-rule-style : $columnRuleStyle;
	-moz-column-rule-width : $columnRuleWidth;
	column-width : $colwidth;
	column-count : $colcount;
	column-gap : $colgap;
	column-rule-color : $columnRuleColor;
	column-rule-style : $columnRuleStyle;
	column-rule-width : $columnRuleWidth;
}


//
//Creamos prefijos para todo dios
//
//   `@include vendor(border-radius, 4px);`
//
//
@mixin vendor($property, $value...){
    -webkit-#{$property}:$value;
       -moz-#{$property}:$value;
        -ms-#{$property}:$value;
         -o-#{$property}:$value;
            #{$property}:$value;
}

/* 
 * Función para controlar el index 
 */
 
@function z($layer) {
  @if not map-has-key($z-layers, $layer) {
    @warn "No layer found for `#{$layer}` in $z-layers map. Property omitted.";
  }
 
  @return map-get($z-layers, $layer);
}
