




/*------------------------------------*\
    $BOTONES
\*------------------------------------*/
/*
 * Definimos los botones y sus variantes
 */


 /**
 * .btn es la clase principal para definir un botón
 */
.btn {
	display : inline-block;
	line-height : $baselineheight * 1.5;
	color : $white;
	text-align : center;
	vertical-align : middle;
	white-space:nowrap;
	background-color : $buttonbackground;
	border : none;
	cursor : pointer;
	margin:0;
	padding: .25em 1em;
	-webkit-appearance: none;
	font-family: $basefont;
	@include font-size(16);
	@include border-radius($radius,$radius,$radius,$radius);

	&:hover {
		text-decoration : none;
		color : $white;
		background : $buttonbackground;
	}

	&:active,
    &:focus{
        outline:none;
        color : $white
    }
    &:visited {
		color : $white;
	}
}

button{
	background: transparent;
    border: 0;
    padding: 0;
}

/*------------------------------------*\
    $BOTONES-VARIANTES
\*------------------------------------*/
/**
 * Variantes de los botones: color para dar sensaciones.
 *
 */


/**
* Inputs con estilo de botones
*/
input[type="submit"].btn:hover {
	border-color : $buttonbordercolor;
	-webkit-appearance:none;
}


/**
* Botón no disponible
*/
.btn--disabled {
    @include opacity(.5);
	cursor : default;
}


/**
* Botón de advertencia
*/
.btn--default {
	background-color: $basecolor;
	color : $color-default;

	&:hover {
		background : darken($basecolor, 5%);
		color:$white; 
	}
}

.btn--arrow{
	@extend .btn--arrow__common;
	@extend .btn--arrow__right;
}

.btn--arrrow__return{
	@extend .btn--arrow__common;
	@extend .btn--arrow__left;
}

	.btn--arrow__common{
		position: relative;
		line-height: 1.5;
	    text-transform: uppercase;
	    @include font-size(12);
		height: 24px;


	    &.btn--arrow__large{
	    	padding-right: 2rem;
	    }
	}

	.btn--arrow__left{
		margin-left: 15px !important;
		
	    &:before{
	    	content: "";
	    	position: absolute;
	    	top: 0;
	    	left: -13px;
	    	display: block;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 12px 13px 12px 0;
			border-color: transparent $basecolor transparent transparent;
	    }

	    &:hover{
			background : darken($basecolor, 5%);
	    	
	    	&:before{
	    		border-color: transparent darken($basecolor, 5%) transparent transparent;	
	    	}
    	}
	}

	.btn--arrow__right{
		margin-right: 15px !important;
		
	    &:after{
	    	content: "";
	    	position: absolute;
	    	top: 0;
	    	right: -12px;
	    	display: block;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 12px 0 12px 13px;
			border-color: transparent transparent transparent $basecolor;
	    }

	    &:hover{
			background : darken($basecolor, 5%);
	    	
	    	&:after{
	    		border-color: transparent transparent transparent darken($basecolor, 5%);	
	    	}
    	}
	}

/**
* Botón de Error
*/
.btn--error {
	background-color: $color-error;
	color : $white;
	&:hover {
		background-color : darken($color-error, 10%);
	}
}

/**
* Botón de Info
*/
.btn--info {
	background-color: $color-info;
	color : darken($color-info, 40%);
	&:hover {
		color : lighten($color-info, 40%);
	}
	&:visited {
		color : darken($color-info, 40%);
	}
}

.btn--return{
	position: relative;
	display: inline-block;
	padding-left: 25px;
	vertical-align: middle;
	text-transform: uppercase;
	@include font-size(14);
	
	&:before{
		content: "";
		position: absolute;
		display: inline-block;
		top: 50%;
		left: 0;
		margin-top: -7px;
		width: 17px;
		height: 14px;
		background: url("#{$img-dir}/template/sprites.png");
		background-position: -60px -23px;
		vertical-align: middle;
	}

}

.btn--zoom{
	span{
		display: inline-block;
		background: url("#{$img-dir}/template/sprites.png");
	}

	&.in{
		span{
			background-position: -312px -62px;
			width: 23px;
			height: 24px;
		}
	}

	&.out{
		span{
			background-position: -261px -62px;
			width: 23px;
			height: 24px;
		}
	}

	&.download{
		span{
			background-position: -213px -62px;
			width: 20px;
			height: 23px;
		}
	}

	&.fit{
		span{
			background-position: -162px -62px;
			width: 23px;
			height: 23px;
		}
	}
}

.btn--download{
	position: relative;
	padding: .25em 2em .25em 1em;
	text-transform: uppercase;
	@include font-size(12);

	&:after{
		position: absolute;
		content: "";
		top: 50%;
		margin-top: -3px;
		right: 10px;
		background: url("#{$img-dir}/template/sprites.png");
		background-position: -8px -29px;
		width: 8px;
		height: 7px;
	}
}

.btn--special{
	display: block;
	position: relative;
	width: 100%;
	padding: 2rem 0 2rem 6rem;
	background: $basecolor;
	color: $white;

	@media only screen and (max-width: 769px) {
		padding: 1rem;
		text-align: center;
	}

	&:hover{
		color: $white;
		background: darken($basecolor, 10%);
	}

	&:visited{
		color: $white !important;
	}


	&:before, &:after{
		content: "";
		display: block;
		position: absolute;
		background: url("#{$img-dir}/template/sprites.png");
	}

	&:before{
		left: 25px;
		top: 50%;
	}

	&.download{
		&:after{
			right: 25px;
			top: 50%;
			margin-top: -7px;
			background-position: -316px -16px;
			width: 17px;
			height: 14px;
		}
	}

	&.map{
		&:before{
			margin-top: -25px;
			background-position: -350px 0;
			width: 49px;
			height: 49px;
		}
	}

	&.picture{
		&:before{
			margin-top: -23px;
			background-position: -418px 0;
			width: 56px;
			height: 46px;
		}	
	}

	&.plan{
		&:before{
			margin-top: -23px;
			background-position: -502px 0;
			width: 46px;
			height: 46px;
		}	
	}
}

/**
* Botón principal
*/

.btn--primary {
	background-color: $basecolor;
	color: $white;
	&:hover {
		color : lighten($basecolor, 40%);
	}
	&:visited {
		color : darken($basecolor, 40%);
	}
}

/**
* Botón de OK
*/

.btn--success {
	background-color: $color-success;
	color : $white;
	&:hover {
		background-color : darken($color-success, 10%);
	}
}



/**
* Botón de advertencia
*/
.btn--warning {
	background-color: $color-warning;
	color : darken($color-warning, 40%);
	&:hover {
		color : lighten($color-warning, 40%); }
	&:visited {
		color : darken($color-warning, 40%);
	}
}

.btn--inactive,
.btn--inactive:hover,
.btn--inactive:active,
.btn--inactive:focus{
	@include opacity(.5);
	cursor : default;
 }



/*------------------------------------*\
    $BOTONES-TAMAÑOS
\*------------------------------------*/
/**
 * Variantes de los botones por tamaño.
 *
 */

.btn--mini-custom{
    padding: .5em;
    line-height:1.5;
    @include font-size(12);
}

.btn--mini{
    padding-right:0.5em;
    padding-left: 0.5em;
    line-height:1.5;
}

.btn--smallest{
    padding: 1em .75em;
    line-height:1.5;
    @include font-size(9);
}

.btn--small{
    padding-right:1.5em;
    padding-left: 1.5em;
    line-height:2;
    @include font-size(14);
}

.btn--large{
    padding-right:3em;
    padding-left: 3em;
    @include font-size(16);
}

.btn--call{
	margin: 2em 0;
}


.btn--lng-text{
	letter-spacing: 0;
	padding: .25em .5em;
}



/*------------------------------------*\
    $BOTONES-GRUPOS
\*------------------------------------*/
/**
 * Con esta clase podemos agrupar los botones
 * en grupos horizontales.
 *
 */
.btn--group {
	position : relative;
	*margin-left : .3em;
}

.btn--group .btn {
	position : relative;
	border-radius: 0;
	float : left;
	margin-left : -1px;
}

.btn--group .btn:first-child {
	margin-left : 0;
	@include border-radius(0, 0, $radius, $radius);
}

.btn--group .btn:last-child {
	@include border-radius($radius, $radius, 0, 0);
}

.btn--group .btn.large:first-child {
	margin-left : 0;
	@include border-radius($radius, 0, $radius, 0);
}

.btn--group .btn.large:last-child {
	@include border-radius(0, $radius, 0, $radius);
}
