/*------------------------------------*\
    $bp1	480px
\*------------------------------------*/

.info-website>.wrapper{
    max-width: 465px;
}

/*
*	FOOTER
*/

footer{
	>.wrapper{
		max-width: 435px;
	}
}

.info-website>.wrapper{
    max-width: 435px;

    .col{
        display: block;
    }
}

.social a{
	margin: 0 .2em 0 0;
	padding: 0 .2em 0 0;
}

.timetable {
	.col {
		display: block;

		ul{
			padding: 15px;
		}
	}
}

.section--featured__child {
	min-height: inherit;
}