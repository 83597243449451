/*------------------------------------*\
    $LINKS
\*------------------------------------*/
a {
	text-decoration : none;
	color : $linkcolor;
	outline: 0;

	&:visited {
		color : $linkcolorvisited;
		outline: 0;
	}

	&:hover {
		text-decoration : none;
		color : $linkcolorhover;
	}

	&:focus {
		outline: 0;
		color : $linkcolorfocus;
	}

	&:hover,
	&:active,
	&:focus {
		outline : 0;
	}
}

.link-sn{
	&:hover{
		color : $lightgrey;
	}
}