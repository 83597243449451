/*------------------------------------*\
    $bp3	768px
\*------------------------------------*/


/*
*	HEADER
*/
header.header{

	.wrapper{

        .logo-cont,
        .social-cont{
            float: none;
            width: 100%;
            @include unstack();
        }

        .logo-cont{
          text-align: center;
        }

        .social-cont{
            text-align: right;
        }
	}
}

   /*
	*	OPTIONS WEBSITE
	*/
	.options--website{

        .link-menu-toggle{
            display: block;
        }
	}



/*
*	NAV
*/
nav{
	display: none;

	.menu{
		display: block;
		li{
			display: block;
		}

	}

    .menu, .submenu{
        li{
            &:first-child{
                a{
                    padding-left: 0em;
                }
            }

            &:last-child{
                a{
                    text-align: left;
                }
            }
        }

        a{
            display: inline-block;
            padding: .5em 0;
            text-align: left;
        }
    }

	.submenu{

		li{
			display: block;
          text-align: left;
		}

        a{
             padding: .5em 0;
            text-transform: initial;
        }
	}
}

/*
*	FOOTER
*/

footer{

    display: block;

	>.wrapper{
		max-width: 700px;
	}
}

.info-website>.wrapper{
    max-width: 740px;
}

/*
*	CUSTOM FORM
*/
.form-search .searchText{
    width: 75%;
}


.info-website>.wrapper .col{

}

/*
*	LIST ELEM
*/

//.list-elem{
//
//
//    &.list-elem__2,
//    &.list-elem__3,
//    &.list-elem__4{
//        >li{
//            width: 100%;
//            margin-right: 0;
//        }
//    }
//
//}


/*
*	STYLES COMMON
*/
.section{

    .row{
        display: block;

        .wrapper{
            max-width: 100%;
        }
    }
}

/*
*	SECTION FEATURED
*/


.section--featured{

    &.section--featured__3{
        >.col{
            &:first-child{
                width: 100%;
              margin-right: 0;
              padding: 0;
            }
        }
    }

    >.col{
        display: block;
        width: 100%;

        &:first-child{
            width: 100%;
          margin-right: 0;
          padding: 0;
        }
    }

}



/*
*	LIST EDUCATIONAL
*/
.list-box{

    .wrapper{

        .col{
            display: block;

            &:first-child{
                width: 100%;
            }

            & + .col{
                min-height: 170px;
                padding: 15px 0;
            }
        }
    }

}


/*
*	LIST
*/
.row--list{
	.col-2{
		display: block;
		width: 100%;
	}
}

/*
*	VISIT GUIDE
*/
.visit{
    display: block;
    width: 100%;
    padding: 0 15px;

    .col{
        display: block;

        &:first-child{
            width: 100%;
            padding-right: 5%;
        }

        &:last-child{

        }
    }

    //.visit--description{
    //    li{margin-bottom: 1em;}
    //    p{margin:0;}
    //}
}


/*
*	ARTISTS
*/
.filter-artist{

	.col{
		display: block;

		& + .col{text-align: left; margin-top: 15px;}
	}
}


/*
*	PUBLICACIONES
*/
/* clearfix */
.owl-main-wrapper{
	width: 320px !important;
}







