/*------------------------------------*\
    $STYLE
\*------------------------------------*/
/**
 * Aquí incluímos todos los módulos (archivos .scss) que se van
 * a compilar en el CSS definitivo: /css/style.css.
 * Si no usas algún módulo, es recomendable que elimines
 * o comentes su inclusión para no tener estilos innecesarios en tu CSS final
 * y optimizar lo máximo posible.
 */
$media-queries-supported : true;

@import "contenido.scss";

@import "lib/variables.scss";

@import "lib/mixins.scss";

@import "lib/flex.scss";

@import "lib/placeholders.scss";

@import "lib/jeet";

@import "base/reset.scss";

@import "base/fuentes.scss";

@import "base/links.scss";

//@import "componentes/tablas.scss";

@import "componentes/botones.scss";

@import "base/elementos.scss";

@import "base/tipografia.scss";

@import "componentes/navegacion.scss";

@import "componentes/paginacion.scss";

@import "componentes/carousel.scss";

@import "base/formularios.scss";

@import "lib/attr.scss";

@import "layout/sitio.scss";

@import "layout/breakpoints.scss";

// @import "debug.scss";  // Utilizado para  comprobar que el marcado HTML no contenga errores

@import "componentes/home-popup.scss";

@import "base/custom.scss";