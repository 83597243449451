/*------------------------------------*\
    $bp5	1100px
\*------------------------------------*/

/*
*	CUSTOM FORM
*/
.form-search .searchText{
    width: 60%;
}


/*
*	FOOTER
*/

footer{
	>.wrapper{
		max-width: 1000px;
	}
}

.info-website>.wrapper{
    max-width: 1000px;
}