.home-popup-blackout {
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    display: none;
    justify-content: center;
    align-items: center;
    top:0;
    right: 0;
    z-index: 98;
  }
  .home-popup {
    position: relative;
    display: none;
    background: #e1e1e1;
    background-size: cover;
    background-repeat: no-repeat;
    border: 0px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    padding: 10px;
    z-index: 99;

    p {
      font-size: 18px;
      display: block;
      text-align: center;
    }

    div.home-popup-close {
      background: #e1e1e1;;
      color: #7d7167;
      display: block;
      position: absolute;
      top: -15px;
      right: -15px;
      cursor: pointer;
      width: 35px;
      height: 35px;
      text-align: center;
      border-radius: 100%;

        &:before{
          display: inline-block;
          font-family: 'fontawesome';
          content: '\f057';
          vertical-align: middle;
          font-size: 25px;
          line-height: 33px;
        }
    }

    .home-popup-content{
      text-align: center;
      img{
        margin: 10px 0;
      }

    }

  }
  
  @media (max-width: 769px){
    .home-popup {
      width: 80%;
      div.home-popup-close {
        &:before{
          line-height: 35px;
        }
      }
    }
  }