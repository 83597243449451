/*------------------------------------*\
    $NAVEGACIÓN
\*------------------------------------*/
nav{
	position: relative;
	display: table;
	width: 100%;
	background: $greyMenu;

	.menu{
      //display: block;
      width: 100%;
      max-width: $pagemaxwidth;
      margin: 0 auto;
      padding: 0 15px;
      padding: 0 1em;
      text-align: center;
			display: flex;
			justify-content: space-between;
			align-items: center;
      li a {
        font-size: 16px;
      }
        @media only screen and (min-width: 769px) {
          li a{
            font-size: 13px;
          }
        }
        @media only screen and (min-width: 1040px) {
          padding: 0 0;
          li a {
            font-size: 16px;
          }
        }

        li{
          display: inline-block;
          vertical-align: middle;
          margin: 0;
          &:last-child, &:first-child{
            margin: 0;
          }
          @media only screen and (min-width: 769px) {
            //margin: 0 16px;
          }
        }

		a{
			text-transform: uppercase;
		}

      .active{
          color: $brownDark;
          background: $brownLight;
      }
		
	}

		.menu, .submenu{
			li{
				&:first-child{
					a:first-child{
						text-align: left;
                      padding-left: 0;
                        //@media only screen and (min-width: 769px) {
                        //  padding-left: 0;
                        //}
					}
				}

				&:last-child{
					a:first-child{
						text-align: left;
                        @media only screen and (min-width: 769px) {
                          text-align: right;
                        }
						padding-right: 0;
					}
				}
			}

			a{
				display: block;
				/* padding: 1em;  ANTERIOR */
				padding: 1em .4em;
				font-weight: bold;
				text-align: center;
			}
		}

	.submenu{
		width: 100%;
		max-height: 0;
    	overflow: hidden;
		background: $grey;
		@include transition(.75s all ease-in-out);
		z-index: 15;

		&.active, &:hover{
			max-height: 100vh;
			@include transition(.75s all ease-in-out);

		}

		.wrapper{
			display: table;
			width: 100%;
			max-width: $pagemaxwidth;
			margin: 0 auto;
		}

		li{
			display: inline-block;
			vertical-align: middle;
		}

			.submenu__list{
				display: none;
              padding: 0 15px;
				&.active{display:block;}
			}
	}
}