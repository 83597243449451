/*------------------------------------*\
    $CONTENIDO
\*------------------------------------*/
/**
 * Contenido..................... Esto que lees

 * Variables..................... Configuramos todas las variables del framework

 * Mixins........................ Mixins de LESS

 * Helpers....................... Clases de ayuda

 * Reset......................... Reinicio de valores CSS

 * Fuentes....................... Fuentes que vamos a importar

 * Links......................... Formas y estilos de los links

 * Tablas........................ Estilos de las tablas

 * Botones....................... Estilos de Botones, skins y variantes

 * Elementos..................... Elementos varios como Imagen, HR, etc...

 * Tipografía.................... Encabezados, small, párrafos, listas, etc...

 * Navegación.................... Una navegación, por si quieres usar esta y no la tuya propia

 * Formularios................... Estilos de todos los elementos de formularios y sus variantes

 * Sitio......................... Aquí escribes tus estilos para la web se vea como tu quieres ;)

 * Paginación.................... Estilos básicos para las paginaciones

 * Texturas...................... Badges, cajas y wells

 * Breakpoints................... Importamos breakpoints basados en las variables @bp$

 * Patron........................ Estilos para la guía de patrones
 */