/*------------------------------------*\
    $VARIABLES
\*------------------------------------*/
/**
 *
 * Definimos y configuramos las variables CSS que vamos a utilizar
 *
 */


/*------------------------------------*\
    $BREAKPOINTS
\*------------------------------------*/
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 */

$bp1 : 30em;
$bp2 : 40em; // 640px;  // 37.5em; --> 600px
$bp3 : 48em;
$bp4 : 56.25em;
$bp5 : 68.75em;
$bp6 : 80em;





/*------------------------------------*\
    $no-mediaquery-class
\*------------------------------------*/
/**
 * Si decides incluir lt-ie9 para dar soporte a navegadores sin estilos
 * esta es la clase que se añadirá a todos los elementos.
 */
$no-mq-classname: ".ie8-sucks";





/*------------------------------------*\
    $LIBRARYS-DIR
\*------------------------------------*/
/**
 * Con agregar esta variable a las imágenes declaradas en CSS apañamos:
 */
$img-dir: "../img";
$svg-dir: "../svg";

 //Use: background-image:url("#{$image-dir}/btn.jpg");





/*------------------------------------*\
    $LAYOUT
\*------------------------------------*/
/**
 * Definimos elementos constantes del layout
 */
$baselineheight :     1.25em;
$baseline :           $baselineheight;
$gutterwidth :        24px;
$pagemaxwidth :       1024px;
$radius :             0;
$hovertransition :    .3s;
$animationtime :      .8s;




$z-layers: (
  "goku":            9001,
  "shoryuken":       8000,
  "default":            1,
  "below":             -1,
  "bottomless-pit": -9000
);

/*------------------------------------*\
    $FONT-FACE
\*------------------------------------*/
/**
 * Definimos las fuentes a utilizar:
 * $basefont para todos los textos inline
 * $altfont para textos alternativos, como citas, intro, etc...
 * $codefont para código
 */
$basefont           : "Arial Narrow",Arial,sans-serif;
$fontSecondary      : "Times New Roman", Times, sans-serif;
$altfont            : Georgia, Times, "Times New Roman";
$codefont           : "Courier New", monospace;
$baseheadingfont    : inherit;

$baseheadingfontweight : bold;
$baseheadingfontcolor : inherit;

$altlineheight : 1.35em;





/*------------------------------------*\
    $TAMAÑOS
\*------------------------------------*/
/**
 * Definimos las fuentes a utilizar:
 * $basefont para todos los textos inline
 * $altfont para textos alternativos, como citas, intro, etc...
 * $codefont para código
 */
$basefontsize :      16;





/*------------------------------------*\
    $COLORS
\*------------------------------------*/

/*--- COLLECTION COLORS ---*/
$brown                : #7d7167;
$brownLight           : #a9a19b;
$brownDark            : #443d3d;

$grey                 : #e3e1df;
$greyLight            : #eeeceb;
$greyMenu             : #f6f5f4;
$greyDark             : #cdcdcd;

$black                : #443d3d;

$white                : rgb(255,255,255);
$whiteTransparent     : rgba(255,255,255,0.78);
$whiteTransparentLight: rgba(255,255,255,0.5);

$blackTransparent     : rgba(0,0,0,0.35);
$blacklight           : #272727;
$blackligthsecond     : #3a3a3a;

/**
 * Brand stuff
 */
$basecolor      : $brown;
$basecolorLight : $brownLight;
$basecolorDark  : $brownDark;
$secondarycolor : $black;
$thirdcolor     : $greyMenu; 
$bordercolor    : lighten($secondarycolor, 50); 
$compcolor      : rgb(216,240,227);

/**
 * Escala de grises
 * A veces no queremos que el negro sea "negro" o el blanco "blanco", puedes cambiarlos.
 */
$black        : rgb(0,0,0);
$superdark    : lighten($black, 10%);
$darkergrey   : lighten($black, 15%);
$darkgrey     : lighten($black, 25%);
$lightgrey    : lighten($black, 75%);
$lightergrey  : lighten($black, 90%);



// Web
$background       :  $white;
$textcolor        :  $brown;
$textcolor-strong :  $black;

// Links  //////////////////////
$linkcolor        : $basecolor;
$linkcolorhover   : darken($linkcolor, 10);
$linkcolorvisited   : $basecolor;
$linkcolorfocus   : darken($linkcolorvisited, 10);

/**
* CUSTOM
*/
$blackcustom:#4D4D4D;





/*------------------------------------*\
    $TEXTURAS
\*------------------------------------*/
/**
 * Definimos Colores y elementos de popups, mensajes de alerta, etc...
 */
$color-alert    : rgb(252,228,207);
$color-error    : rgb(218,79,73);
$color-info     : rgb(165,221,223);
$color-inverse  : invert($secondarycolor);
$color-success  : rgb(91,183,91);
$color-warning  : $color-error;
$color-default  : $white;

$back-color-default: transparent;


// Texturas de popups
$alertbordercolor : darken($color-alert, 20%);
$alertborderwidth   : 1px;
$alertborderstyle   : solid;
$alerttext      : darken($color-alert, 60%);

// Error
$alerterrorcolor  : lighten($color-error, 30%);
$alerterrorborder   : lighten($color-error, 20%);
$alerterrortext   : $color-error;

// Info
$alertinfocolor   : lighten($color-info, 10%);
$alertinfoborder    : darken($color-info, 10%);
$alertinfotext    : darken($color-info, 50%);

// Inverso
$alertinversecolor  : $color-inverse;
$alertinverseborder : $black;
$alertinversetext   : $white;

// Success
$alertsuccesscolor  : lighten($color-success, 30%);
$alertsuccessborder : lighten($color-success, 20%);
$alertsuccesstext   : darken($color-success, 20%);

// Advertencia
$alertwarningcolor  : lighten($color-warning, 30%);
$alertwarningborder : lighten($color-warning, 20%);
$alertwarningtext   : darken($color-warning, 20%);

/* Colores sociales -> son los colores corporativos, pero cambiadlos
 * si hace falta. (también podéis añadir más)  ///////////////////////
 */
$fb :rgb(59, 89, 182);
$tw :rgb(64,153,255);
$googleplus :rgb(221, 75, 57);
$pinterest :rgb(203, 32, 39);
$skype :rgb(18,165,244);
$spotify :rgb(129,183,26);
$instagram:#4E433C;
$tumblr: #2B4964;
$vimeo: #86B32D;
$soundcloud: #f76600;
$youtube: #FF3333;
$linkedin: #4875B4;
$flickr: #FE0883;
$foursquare: #8FD400;


// badges
$badgebackground    : $basecolor;
$badgecolor         : $textcolor;

// botones
$buttonbackground   : $basecolor;
$buttonbordercolor  : darken($buttonbackground, 10%);
$buttonbottombordercolor : darken($buttonbordercolor, 10%);
$buttoncolor        : $black;
$buttonborderwidth  : 1px;
$buttonborderstyle  : solid;

// boxes (well)
$wellbackground     : lighten($basecolor, 75%);
$wellbordercolor    : darken($wellbackground, 20%);
$wellborderwidth    : 2px;
$wellborderstyle    : solid;
$wellshadow         : darken($wellbackground, 10%);
$wellshadowblur     : 10px;

// tablas
$tablebackground    : transparent;
$tablestripe        : lighten($basecolor, 60%);
$tablehover         : lighten($basecolor, 50%);
$tableborder        : lighten($basecolor, 40%);
$tableborderwidth   : 2px;
$tableborderstyle   : solid;

// formularios
$inputbackground    : transparent;
$inputborder        : $secondarycolor;
$inputborderwidth   : 1px;
$inputborderstyle   : solid;
$inputhover         : lighten($black, 50%);
$inputfocus         : $basecolor, 40%;
$placeholdercolor   : $lightergrey;


// Navegación
$nav-width:               44px;
$nav-height:              $nav-width * 1.2;
$nav-icon-size:           16px;
$nav-color:               white;
$nav-background:          $secondarycolor;
$nav-borders:             lighten($nav-background, 10%);
$nav-lists-background:    $basecolor;
