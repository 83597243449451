
//DISPLAY
.block{
	display:block;
}

.hide{
	display:none !important;
}
	
	.block-inline{
		display: inline;
		float: left;
		margin: 0 1rem 1rem 0;
	}

.ellipsis{
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


//ATRIBUTES TEXT
.text-small{
	@include font-size(12);
}
.text-intermenidum{
	@include font-size(14);
}
.text-medium{
	@include font-size(16);
}
.text-big{
	@include font-size(21);
}
.text-big-2{
	@include font-size(24);
}
.text-title-product{
	@include font-size(36);
}
.text-title-section{
	@include font-size(55);
}

	//colors
	.text-color{
		color: $basecolor;
	}
	.text-color-2{
		color: $secondarycolor;
	}
	.text-color-none{
		color: $white;
	}
	.text-color-grey{
		color: $grey;
	}


.text-primary{
	font-family: $basefont;
	font-style: normal;
}
.text-second{
	font-family: $basefont;
	font-style: normal;
}


.text-italic{
	font-style: italic;
}
.text-bold{
	font-weight: bold;
}
.text-inherit{
	font-weight: 300;
}

.text-line{
	line-height: 1;
}

.text-spacing-top{
	margin-top: 2em;
}


.title-first-small{
	font-family: $basefont;
}
.title-uppercase, .text-uppercase{
	text-transform: uppercase; 
}
.title-lowercase, .text-lowercase{
	text-transform: lowercase; 
}

.title-lowercase-start{
	text-transform: lowercase; 

	&:first-letter{
		text-transform: uppercase !important;
	}	
}

.text-center{
	text-align: center;
}
.text-left{
	text-align: left;
}
.text-right{
	text-align: right;
}

.title-category{
	background: $secondarycolor;
}


.text-underline{
	text-decoration: underline;
}

//STYLES TEXT
.border-bottom{
	display: block;
	width: 10%;
	border-bottom: 1px solid $basecolor;
	margin: 0 auto;
	margin-top: .25em;
}

.spacing-vertical{
	margin: 1em 0;
}

.spacing-horizontal{
	margin: 0 1em;
}


//MARGINS

.m-s{
	margin:.25rem;
}
.m-m{
	margin:.5rem;	
}
.m-n{
	margin:1rem;
}
.m-b{
	margin:2rem;
}

.mt-s{
	margin-top:.5rem;
}
.mt-m{
	margin-top:1rem;
}
.mt-n{
	margin-top:1.5rem;
}
.mt-b{
	margin-top:2rem;
}

.mb-small{
	margin-bottom:.25rem;
}
.mb-m{
	margin-bottom:.5rem;
}
.mb-n{
	margin-bottom:1rem;
}
.mb-b{
	margin-bottom:2rem;
}
.mb-h{
	margin-bottom:4rem;
}

.ma-s{
	margin:.25rem 0;
}
.ma-m{
	margin:.5rem 0;
}
.ma-n{
	margin:1rem 0;
}
.ma-b{
	margin:2rem 0;
}
.ma-h{
	margin:4rem 0;
}

.mh-s{
	margin:0 .25rem;
}
.mh-m{
	margin:0 .5rem;
}
.mh-n{
	margin:0 1rem;
}
.mh-b{
	margin:0 2rem;
}

//PADDING
.p-s{
	padding:.25rem}
.p-m{
	padding:.5rem;	
}
.p-n{
	padding:1rem;
}
.p-b{
	padding:2rem;
}

.pt-s{
	padding-top:.25rem;
}
.pt-m{
	padding-top:.5rem;
}
.pt-n{
	padding-top:1rem;
}

.pb-s{
	padding-bottom:.25rem;
}
.pb-m{
	padding-bottom:.5rem;
}
.pb-n{
	padding-bottom:1rem;
}

.pa-s{
	padding:.25rem 0;
}
.pa-m{
	padding:.5rem 0;
}
.pa-n{
	padding:1rem 0;
}
.pa-b{
	padding:2rem 0;
}

.ph-s{
	padding:0 .25rem;
}
.ph-m{
	padding:0 .5rem;
}
.ph-n{
	padding:0 1rem;
}
.ph-b{
	padding:0 2rem;
}