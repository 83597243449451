/*------------------------------------*\
    $bp4	900px
\*------------------------------------*/


/*
*	CUSTOM FORM
*/
.form-search .searchText{
    width: 55%;
}
