




/*------------------------------------*\
    $TIPOGRAFÍA
\*------------------------------------*/


// HEADINGS ====================================================

h1,
h2,
h3,
h4,
h5,
h6 {
	margin : 0;
	font-family : $baseheadingfont;
	color : $baseheadingfontcolor;
	font-weight:normal;
	text-rendering : optimizelegibility;
		&.altheader {
			color:$grey;
			text-transform: uppercase;
		}
}

h1 small,
h2 small,
h3 small {
	font-weight : normal;
	display: block;
	color : $grey;
}

@each $header, $margin-bot, $font-size, $line-height in (h1, 2, 32, 1.2)
                                                        (h2, 3, 24, 1.2)
                                                        (h3, 4, 21, 1.3)
                                                        (h4, 6, 18, 1.25)
                                                        (h5, 6, 16, 1)
                                                        (h6, 6, 14, 1){
  #{$header}, .#{$header} {
  	margin-bottom : $baselineheight / $margin-bot;
  	@include font-size($font-size);
  	line-height : $line-height;
  }
}
// TYPOGRAFÍA ====================================================

p,
ol,
ul,
dl,
address {
	margin-bottom : $baselineheight;
	line-height : $baselineheight;
}

.intro {
 	font-weight: bold;
}

small {
	@include font-size(12);
	color: $grey;
}

ul{
	/* margin : 0 0 $baselineheight 0;
	padding : 0 0 0 $gutterwidth; */
	margin:0;
	padding:0;
	list-style: none;

	&.list--disc{
		list-style: disc;
		list-style-position: inside;
		margin: 1rem;
		padding-left: 1rem;
		text-indent: -4px;
	}

	&.list--indented{
		padding-left: 3rem;
	}

	&.list--margin{
		margin: 0 1rem 1rem 1rem;
		padding-left: 1rem;
	}
}

ol{
	list-style-type: decimal;
	list-style-position: inside;
	margin: 0 0 .5em 0;
}

li ul,
li ol {
	margin : 0;
}

// blockquote

blockquote  {
	margin : 0 0 $baselineheight 0;
	padding-left : $gutterwidth;
	border-left : 2px solid $bordercolor;
	font-family : $altfont;
	font-style : normal;
	p {
		@include font-size(18);
		color:$grey;
		font-style: italic;
	}
}

q {
	quotes : none;
	font-style: italic;
}

blockquote p::before,
blockquote p::after,
q:before,
q:after {
	content : "";
}

cite {
	font-style : normal;
}

// definition lists

dl,
dd {
	margin-bottom : $baselineheight;
}

dt {
	font-weight : bold;
}

.dl-horizontal {

	dt {
		float : left;
		clear : left;
		width : 20.25%;
		text-align : right;
	}

	dd {
		margin-left : 22.78%;
	}
}

// text-level

abbr[title] {
	border-bottom : 1px dotted $bordercolor;
	cursor : help;
}

b, strong {
	font-weight : bold;
}

dfn {
	font-style : italic;
}

ins {
	background-color : lighten($basecolor, 75%);
	color : $textcolor;
	text-decoration : none;
}

mark {
	background-color : yellow;
	color : $textcolor;
}

pre,
code,
kbd,
samp {
	font-family : $codefont;
	@include font-size(14);
	line-height : $baselineheight;
}

pre {
	white-space : pre;
	white-space : pre-wrap;
	word-wrap : break-word;
}

sub,
sup {
	position : relative;
	@include font-size(12);
	line-height : 0;
	vertical-align : $baselineheight;
}

sup {
	top : -.5em;
}

sub {
	bottom : -.25em;
}