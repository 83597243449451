ul.sublist {
    li{
        display: flex;

        > span.dot-list{
            margin-right: 10px;
        }

        a{
            text-decoration:  underline;
        }
    }
}

ul.sublist-detail p{
    font-size: 90%;
}

div.horarys{
    table > tbody > tr > td:nth-child(2){
        text-align: right;
    }
}

@media only screen and (max-width: $bp2) {
    .template-col,
    .template-exposition {

        .col {
            display: block;
            width: 100%;

            & + .col {
                padding: 0 1em;
                margin-top: 2em;
            }
        }

        .share-options {
            margin: 0;
            position: relative;
        }

    }
}