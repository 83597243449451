




/*------------------------------------*\
    $ELEMENTOS
\*------------------------------------*/


hr {
	clear : both;
	display : block;
	margin : $baselineheight * 2 0;
	padding : 0;
	height : 1px;
	border : 0;
	border-top : 1px solid $bordercolor;
}

/*
*	SOCIAL NETWORK
*/
.social-network{
	
	&.social-network--footer{

		a{
			padding: .5em;
			@include font-size(22);
		}

	}

	ul{
		display: inline-block;
		@include font-size(0);
        width: 100%;
        @media only screen and (min-width: 769px) {
          width: auto;
        }
		li{
			display: inline-block;
            width: 14.2%;
            text-align: center;
            @media only screen and (min-width: 769px) {
              width: auto;
              text-align: left;
            }
			&:last-child{
				a{padding-right: 0;}
			}
		}
	}

	a{
		padding: 1em;
		@include font-size(16);
	}
}

/*------------------------------------*\
    $ELEMENTOS-IMÁGENES
\*------------------------------------*/
img {
	max-width : 100%;
	border-width : 0;
	vertical-align : middle;
	-ms-interpolation-mode : bicubic;
}

.img--rounded {
	border-radius :$radius * 4;
}

.img--polaroid {
	padding : $gutterwidth / 2;
	background-color : $white;
	border : 1px solid $bordercolor;
	box-shadow : 0 1px 3px rgba(0,0,0,.1);
}

.img--circle {
	border-radius : 100%;
}




/*------------------------------------*\
    $ELEMENTOS-FIGURE
\*------------------------------------*/
figure {
	margin-bottom : $baselineheight;

	img {
		margin-bottom : $baselineheight / 4;
	}

	figcaption {
		display : block;
		font-weight : normal;
		@include font-size(14);
		font-style : italic;
		color : $grey;
	}
}