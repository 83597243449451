input[name="slider-select-element"] {
    display: none;
}


.carousel{
    position: relative;
    //height: 345px;
    min-height: 345px;
    margin: .5em 0 0 0;

    &.carousel--actividades{
        margin: .5rem 0 4rem 0;

        .image{
            display: block;
            width: 100%;
            height: 345%;

            a{display: block;}
        }
    }

}

#carousel-index.carousel{
    min-height: 0px !important;
}

.item-carousel{
    position: relative;
    width: 100%;
    height: 345px;
    min-height: 345px;

    &.item img{
      max-width: initial;
    }

    .content{
        display: block;
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        position: absolute;
        bottom: 20px;
        left: 20px;

        img{
            width: 40%;
        }

        h2,p{
            @include font-size(14);
        }

    }
}

#carousel-index .item-carousel{
    min-height: 0px !important;
    height: auto !important;
}


    /*
    *   CAROUSEL RECOMMENDED
    */
    .carousel--recommended{
        margin-bottom: 3.75em;

        .item{
            margin-right: .5em;
        }

        .image{
            position: relative;
            width: 100%;
            height: 232px;
            min-height: 232px;
            margin-bottom: 1em;

            a{
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        .content{padding: 0 .5em;}

        h4{
            margin: 0;
            color: $secondarycolor;
            font-family: $basefont;
            font-size: 1.3rem;
	        line-height: 1;
        }

        p{
            margin:0;
            @include font-size(14);
        }
    }

#carousel--featured{
    min-height: 0px !important;
}

#carousel--featured .item-carousel{
    min-height: 0px !important;
}

/*
*   CONTROLS
*/
.controls{
    display: inline-block;
    
    li{
        display: inline-block
    }

    &.controls--recommended{
        width: 150px;
        height: 25px;
        margin-left: .5em;
        vertical-align: middle;

        li{margin-right: .5em;}
    }

    .control{
        position: relative;
        display: inline-block;
        width: 18px;
        height: 18px;
        border: 1px solid $basecolor;
        
        &:after{
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -5px;
            margin-left: -3.5px;
            background: url("#{$img-dir}/template/sprites.png");
            background-position: -29px -25px;
            width: 7px;
            height: 10px;
        }

        &.disabled{
            @include opacity(.5);
        }

        &.control--prev{
            &:after{
                @include rotate(-180deg);
            }
        }
    }
}