/*------------------------------------*\
    $bp2	640px
\*------------------------------------*/



/*
*	FOOTER
*/

footer{
	>.wrapper{
		//max-width: 730px;
        margin: 0;
        padding: 30px 15px;
        width: 100%;

        .col{
            display: block;
            margin-bottom: 30px;
            width: 100%;
        }
	}
}

.info-website>.wrapper{
    max-width: 550px;
    //max-width: 730px;

    .newsletter, .news{
      width: calc(100% - 2em);
      margin: 1em;
      box-sizing: border-box;
      display: block;
    }
    .newsletter{
      margin-bottom: 0;
    }

    .col{
        display: block;
    }

}


/*
*	BREADCRUMBS
*/
.breadcrumbs{

    .list-rute{

        //padding: 0 15px;
    }
}


/*
*	LIST ELEM
*/

.list{

    .list--title{
        padding-left: 0px;
    }

}

.list-elem{

    //padding: 15px;

    >li{
        margin: 0 0 2em 0;
      &:last-child{
        margin: 0;
      }
    }

    &.list-elem__2,
    &.list-elem__3,
    &.list-elem__4{
        >li{
            width: 100%;
            margin-right: 0;
        }
    }

}


/*
*	TITLES
*/
.title--classic{

}



/*
*	PAGES
*/
.title--page{
	margin: 0 0 .75em 0px;

	&.m--small{margin: 0 0 .55em 0px;}
    &.m--small-2{margin: 0 0 .55em 0;}

    &.subtitle--page{ margin:.75em 0; }
}

.title--primary{
    margin-left: 0px;
}

.featured--page,
.featured--page-align,
.tiempo-intro{
    //padding: 0 15px;
}

.colaborativo--intro{
  padding-bottom: 25px;
}

.featured--page{
	.image-floated{
		float: none;
		padding-bottom: 20px;
	}
}

/*
*	STYLES COMMON
*/
//.section{
//
//    .row{
//
//        .wrapper{
//            max-width: 768px;
//        }
//    }
//}


/*
*	SECTION FEATURED
*/

.section--featured{

    //padding: 15px;

    >.col{
        display: block;
        vertical-align: top;
        width: 100%;

        &:first-child{
            width: 100%;
            padding-right: 0;
        }
    }

    .template-work__image{
        float: none;
        text-align: center;
        margin: 0 0 2rem 0;
        width: 100%;
    }

}

.section--align{
    margin-top: 20px;
}


/*
*	SEARCH ACTIVIDADES
*/
	.search-activity{
		display: table;

		>.col{
			display: block;

			&:first-child{
				width:97%;
			}
		}

		.content--col{

			.col{
				display: block;
				width: 100%;

                & + .col{
					border-right: none;
                    padding-right: 0;
				}
			}
		}

        h4{
            margin-top: 20px;
		}

        .calendar{
			max-width: 100%;
		}
	}


/*
*	FILTERS
*/
.filter{
    .title--filter{
        margin-top: .5em;
    }

    &.filter-works{
        .select--custom{ width: 100%; }
        input { max-width: 100%; }
    }

    &.filter-publish{

        .item--form:first-child,
        .select--custom{
            width: 100%;
        }

        .item--form input {
            max-width: 100%;
        }

    }
}

.filter-multimedia{
    .category{
        width: 100%;

        .select--custom{
            width: 100%;
        }
    }
}


/*
*	HORARIO
*/

.table-info {
    .title--page.m--small {
        margin: 0 0 .55em 0;
    }

    .col{
        display: block;
        width: 100%;
    }

    &.table-info--2{
        .col{
            & + .col{
                padding-right: 5%;
            }
        }
    }

    &.table-info--3{
        .col{
            width: 100%;
        }
    }

}

/*
*	TEMPLATES
*/

.template-col,
.template-exposition{

    .col{
        display: block;
        width: 100%;

        & + .col{
            padding: 0 1em;
		}
    }

    .share-options {
        margin: 0;
        position: relative;
    }

}

/*
*	TEMPLATES
*/
//.template-detail{
//	&.vertical{
//		.template-work__image{
//			display: inline;
//			width: auto;
//			float: left;
//			margin: 0 1em 1em 0;
//		}
//
//		.template-work__description--resume{
//			margin: 0;
//			padding: 0;
//		}
//	}
//
//	.template-detail__footer{
//		@extend .board;
//	}
//
//}



//ATRIBUTES TEXT
.text-color-2{
    //padding: 0 15px;
}


/*
*	CUSTOM FORM
*/
.form--filter{
    .row--form{
        display: table;
        width: 100%;
        margin-bottom: .5em;

        .item--form{
            display: block;


            &:nth-child(2){width: 100% !important;}

            label{
                display: block;
            }
        }
    }
}

.form-inline {
	&.contact-alta {
		.select--custom {
			width: 235px;
		}
	}
}

/*
*	PRENSA
*/
.panel--convene{


    aside, .body{
        display: block;
    }

    aside{
        width: 100%;
    }

    .body{
        width: 100%;
        padding-left: 0;
    }

    .select--custom{
      width: 100%;
    }

    .sub-list{
        li{
            display: inline-block;
            padding-right: 15px;
        }
    }
}


/*
*	SOCIAL NETWORK
*/
.social-network{

    &.social-network--footer{
        li{
          //width: 50%;
        }
        a{
            padding: .4em;

        }

    }
}


/*
*	BUTTONS
*/
.btn--arrow{
    margin-right: 15px !important;
}


/*
*	ARTISTS
*/
.filter-artist{
	margin: 1rem;
}

/*
*	DASHBOARD
*/
.dashboard {

}

.search .close {
	margin-top: 5px;
}

/*
*	CORPORACIONES
*/
.list--corporaciones{
    width: 100%;
    margin:3rem 0 5rem 0;

    li{
        width: 100%;
        a {
            display: block;
            text-align: center;
        }
    }
}