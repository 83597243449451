




/*------------------------------------*\
    $PLACEHOLDERS
\*------------------------------------*/

%text-overflow {
	overflow : hidden;
	text-overflow : ellipsis;
	white-space : nowrap;
}

  /**
  * The ghost, nudged to maintain perfect centering
  */
%vcenter {
	&:before {
		content: '';
		display: inline-block;
		height: 100%;
		vertical-align: middle;
	}
}

  /**
  * Escondemos el texto solo VISUALMENTE
  */
%hide-text {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

  /**
  * Escondemos el texto de VISUALMENTE y SCREENREADERS
  */
%hidden {
	display : none !important;
	visibility : hidden;
}


  /**
  * Escondemos pero mantenemos layout.
  */
%invisible {
	visibility : hidden;
}


  /**
  * Visualmente escondido, pero se puede detectar con teclado (focus).
  */
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
	position : static;
	clip : auto;
	height : auto;
	width : auto;
	margin : 0;
	overflow: visible;
}


  /**
  * Clear floats.
  * http://bit.ly/1e3YaBH
  */
%clearfix {
	&:after{
        content:"";
        display:table;
        clear:both;
    }
}




  /**
  * Gradientes los ocnvertimos en placeholders porque imprime 10 millones de
  * líneas inútiles en el CSS
  */
%basegrad {
  @include vertical($basecolor, darken($basecolor, 10%));
}

%errorgrad {
  @include vertical($basecolor, darken($color-error, 10%));
}

%greygrad {
  @include vertical($white, darken($lightergrey, 5%));
}

%infograd {
  @include vertical($color-info, darken($color-info, 10%));
}

%inversegrad {
  @include vertical($color-info, darken($color-info, 10%));
}

%successgrad {
  @include vertical($color-info, darken($color-info, 10%));
}

%warninggrad {
  @include vertical($color-warning, darken($color-warning, 10%));
}
